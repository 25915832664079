import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col, Input, CardBody, Card, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { closeModal, openModal } from 'store/modal/actions';
import DataTable from 'react-data-table-component';
import customStyles from 'styles/customDatatableStyle';
import AcceptModal from 'components/Common/Modals/acceptModal';
import UpdateModal from 'components/Common/Modals/updateModal';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';
import { openUpdateModal } from 'store/updateModal/actions';
import * as XLSX from 'xlsx';
import excel from "assets/icons/excel-white.png";
import { hasCreatePermission, hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import { createDefinition, deleteDefinition, getDefinitions } from 'service/Definitions/definitionsService';
import DefinitionUpdate from './definitionUpdate';
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate, useParams } from 'react-router-dom';
import { setDefinitionItems } from 'store/Definitions/definitionItems/action';

const DefinitionItemPage = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const [formData, setFormData] = useState({});
    const definitionItems = useSelector((state) => state.definitionItems?.definitionItems || []);
    const selectedDefinition = useSelector((state) => state.selectedDefinition?.selectedDefinition || {});
    const [filteredDefinitionDatas, setFilteredDefinitionDatas] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataList = await getDefinitions(id);
                console.log("dataLiiisssttttttt");
                console.log(dataList);
                dispatch(setDefinitionItems(dataList));
                setFilteredDefinitionDatas(dataList);
            } catch (error) {
                console.error('Error fetching loading statuses:', error);
            }
        };
        fetchData();
    }, [dispatch, id]);

    useEffect(() => {
        const filteredData = definitionItems?.filter((data) =>
            data.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"))
        );
        setFilteredDefinitionDatas(filteredData);
        console.log("filteredData");
        console.log(filteredData);
    }, [searchText, definitionItems]);

    const handleChange = (e) => {
        const inputData = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        setFormData({
            ...formData,
            [e.target.name]: inputData,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = () => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal());
                    try {
                        await createDefinition({ ...formData, up_module_id: parseInt(id) });
                        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                        const updatedStatuses = await getDefinitions(id);
                        dispatch(setDefinitionItems(updatedStatuses));
                        setFilteredDefinitionDatas(updatedStatuses);
                        setFormData({});
                    } catch (error) {
                        console.error("Error saving loading status:", error);
                        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                    }
                },
            })
        );
    };

    const handleDelete = (dataId) => {
        dispatch(
            openModal({
                title: props.t("modal-delete-title"),
                body: props.t("modal-delete-body"),
                onConfirm: async () => {
                    dispatch(closeModal());
                    try {
                        await deleteDefinition(dataId);
                        toast.success(props.t("toast-success-delete-data"), { position: "top-right" });
                        const dataList = await getDefinitions(id);
                        dispatch(setDefinitionItems(dataList));
                        setFilteredDefinitionDatas(dataList);
                    } catch (error) {
                        console.error('Error deleting loading status:', error);
                        toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
                    }
                },
            })
        );
    };

    const handleSubMenu = (row) => {
        navigate(`/depot/definition-item/${row.id}`);
    }


    const exportToExcel = () => {
        const selectedColumns = filteredDefinitionDatas.map(status => ({
            [props.t("id")]: status.id,
            [props.t("Name")]: status.name,
            [props.t("Created At")]: status.createdAt,
        }));
        const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Loading Status List"));
        XLSX.writeFile(workbook, props.t("Loading Status List") + ".xlsx");
    };

    const columns = [
        {
            name: props.t("#"),
            selector: (row) => row.id,
            sortable: true,
            wrap: true,
        },
        {
            name: props.t("Name"),
            selector: (row) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: props.t("Status"),
            selector: (row) => row.is_passive, // Sıralama için ham veriyi kullanır
            sortable: true, // Sıralamayı aktif hale getirir  
            cell: (row) =>
                row.is_passive === true ?
                    <Badge>{props.t("Passive")}</Badge> :
                    <Badge color='success'>{props.t("Active")}</Badge>,
            sortFunction: (rowA, rowB) => {
                // "is_passive" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
                return rowA.is_passive === rowB.is_passive ? 0 : rowA.is_passive ? 1 : -1;
            },
            wrap: true,
        },
        {
            name: props.t("Actions"),
            cell: (row) => (
                row.is_passive === true ?
                    restoreIcon(row)
                    :
                    <>
                        <DetailsButton disabled={false} onClick={() => handleSubMenu(row)} />
                        {hasUpdatePermission(permissions, "DE_10") && (
                            <EditButton disabled={false} onClick={() => handleEdit(row)} />
                        )}
                        {hasDeletePermission(permissions, "DE_10") && (
                            <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} />
                        )}
                    </>
            ),
        },
    ];

    const restoreIcon = (row) =>
        <Button size='sm' color="success" onClick={() => handleDelete(row.id)}>
            {props.t("Activate")}
        </Button>;

    const handleEdit = (row) => {
        dispatch(
            openUpdateModal({
                body: <DefinitionUpdate row={row} />,
                onConfirm: () => { },
            })
        );
    };

    return (
        <div>
            {hasCreatePermission(permissions, "DE_10") ?
                <Card>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row className='mb-4' form>
                                <Col>
                                    <FormGroup>
                                        <Label for="name">{props.t("Name")}</Label>
                                        <DynamicInput
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder={props.t("Enter name")}
                                            required
                                            minLength={3}
                                            maxLength={32}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <div className='d-flex justify-content-end mb-4'>
                                        <Button className='text-white' color="success" type='submit'>
                                            {props.t("Save")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>

                : null}


            <Card>
                <CardBody>
                    <h5 className="text-primary"></h5>

                    <DataTable
                        className='p-3'
                        columns={columns}
                        data={filteredDefinitionDatas}
                        customStyles={customStyles}
                        noDataComponent={props.t("table-empty-message")}
                        pagination
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <div className='d-flex justify-content-end w-50'>
                                {hasExcelPermission(permissions, "DE_10") && (
                                    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
                                        <img src={excel} width={'24'} alt="" />
                                    </Button>
                                )}
                                <Input
                                    type="text"
                                    placeholder={props.t("Search")}
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase('tr'))}
                                    style={{ width: '250px' }}
                                />
                            </div>
                        }
                    />
                </CardBody>
            </Card>

            <AcceptModal />
            <UpdateModal />
        </div>
    );
};

DefinitionItemPage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(DefinitionItemPage);
