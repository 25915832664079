import axios from 'axios';
// API URLs
import { API_BASE_URL } from "config";
import { getWithToken } from './common/tokenService';
const API_DASHBOARD = '/dashboard';
const API_MAIN_MENU_GET = '/mainpage';



/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getStatistics = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DASHBOARD}${API_MAIN_MENU_GET}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};
