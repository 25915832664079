import React, { useEffect, useState } from 'react';
import { Button, Table, FormGroup, Card, Badge } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateInslugItemRow, setUpdateInslugItemData, removeUpdateLastInslugItemRow, setUpdateInslugItemsAll, resetUpdateInslugItems, removeUpdateInslugItemRow } from '../../../../store/depot/updateInslugItem/actions';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { inslugItemInfo } from 'service/inslugItemService';
import { useParams } from 'react-router-dom';
import { getInslug } from 'service/inslugService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DynamicInput from 'components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { setSelectedUpdateClientProducts } from 'store/depot/selectedUpdateClientProducts/actions';
import NoAuthText from 'components/noAuth/noAuthText';
import { hasViewPermission } from 'service/common/permissionService';
import NoAuthUpdateInput from 'components/noAuth/noAuthUpdateInput';
/* import { setSelectedUpdateProducts } from 'store/depot/selectedUpdateProducts/actions';
 */
const UpdateInslugItem = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const updateInslugData = useSelector((state) => state.updateInslug);
  const updateInslugItemsData = useSelector((state) => state.updateInslugItems?.updateInslugItems || []);
  const selectedUpdateProducts = useSelector((state) => state.selectedUpdateProducts?.selectedUpdateProducts || []);
  const clientUpdateProducts = useSelector((state) => state.selectedUpdateClientProducts?.selectedUpdateClientProducts || []);

  
  useEffect(() => {
    const updateSelectedProducts = (inslugItems, clientProducts) => {
      inslugItems.forEach((inslugItem, index) => {
        const product = clientProducts.find(product => product.value === inslugItem.client_product_id);
        dispatch(setUpdateInslugItemData(index, "id", inslugItem.id));
        if (product) {
          //dispatch(setUpdateInslugItemData(index, "client_no", product ? product.value : null));
          dispatch(setUpdateInslugItemData(index, "client_product_id", product ? product.value : null));

          /*        dispatch(setUpdateInslugItemData(index, "client_no", selectedOption ? selectedOption.value : null));
                 dispatch(setUpdateInslugItemData(index, "client_product_id", selectedOption ? selectedOption.value : null)); */

        }
      });
    };

    const setDatas = (inslugItem, clientProductData) => {
      dispatch(setUpdateInslugItemsAll(inslugItem));
      updateSelectedProducts(inslugItem, clientProductData);
    };

    const customLabel = (product) => {
      return <div className="d-flex align-items-center">
        {/* <span className='shadow rounded bg-info px-1 text-white mx-2' color="info" pill>{`${product.client_no}`}</span>
        <span className=''>{product.company_name}</span>
        <span className='mx-2'>|</span> */}
        <span className='rounded bg-warning px-1 text-white mx-2' color="info" pill>{`${product.product_no}`}</span>
        <span>{product.product_name}</span>
      </div>;
    }

    const fetchData = async () => {
      try {
        const inslugData = await getInslug(id);
        console.log("inslugDataa");
        console.log(inslugData);
        const clientProductData = await inslugItemInfo();
        const formattedClientProducts = clientProductData.map(product => ({
          value: product.client_product_id,
          clientNo: product.client_no,
          companyName: product.company_name,
          productNo: product.product_no,
          productCategoryName: product.product_name,
          originCountry: product.origin_country,
          label: customLabel(product)
        }));
        dispatch(setSelectedUpdateClientProducts(formattedClientProducts));
        setDatas(inslugData.inslugItems, formattedClientProducts);
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };

    fetchData();
  }, [dispatch, id]);

      useEffect(()=>{
      console.log("CLİENT PRODUCTSSS");
      console.log(clientUpdateProducts);
    },[clientUpdateProducts]);

  useEffect(()=> {
    console.log("updateInslugItemsDataaaaaaaaaaaaaaaaa");
    console.log(updateInslugItemsData);
  },[updateInslugItemsData]);

  // Handle input changes and dispatch actions to update Redux state
  const handleCountChange = (index, e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    const name = e.target.name;
    dispatch(setUpdateInslugItemData(index, name, value));
  };

  const handleSelectChange = (index, field, selectedOption) => {
    //dispatch(setUpdateInslugItemData(index, "client_no", selectedOption ? selectedOption.value : null));
    dispatch(setUpdateInslugItemData(index, "client_product_id", selectedOption ? selectedOption.value : null));
  };

  const handleCreateOption = (inputValue, index) => {
    const newOption = {
      value: inputValue,
      label: (
        <div className="d-flex align-items-center">
          <Badge color="info" pill>{inputValue}</Badge>
        </div>
      )
    };
    dispatch(setSelectedUpdateClientProducts([...clientUpdateProducts, newOption]));

    /* let updatedSelectedProducts = [...selectedUpdateProducts];
    updatedSelectedProducts[index] = newOption; */
    //dispatch(setSelectedUpdateProducts(updatedSelectedProducts));
    //dispatch(setUpdateInslugItemData(index, 'client_no', newOption.value));
  };

  const handleInputChange = (index, e) => {
    dispatch(setUpdateInslugItemData(index, e.target.name, e.target.value.toLocaleUpperCase("tr")));
  };

  const addNewRow = () => {
    dispatch(addUpdateInslugItemRow());
    //dispatch(setSelectedUpdateProducts([...selectedProducts, null]));
  };

  const removeLastRow = () => {
    if (updateInslugItemsData.length > 0) {
      dispatch(removeUpdateLastInslugItemRow());
    }
  };

  const deleteIndexRow = (index) => {
      dispatch(removeUpdateInslugItemRow(index));
  };

  const renderProductLabel = (option) => {
    return (
      <div className="d-flex align-items-center">
        <Badge color="info" pill>{option.clientNo}</Badge>
        <Badge color="warning" pill className="mx-2">{option.productNo}</Badge>
        <Badge color="success" pill>{option.originCountry}</Badge>
      </div>
    );
  };

  return (
    <Card className="p-4 rounded-lg">
      <div className="d-flex mb-3 justify-content-between">
        <h4>{props.t("Product Information")}</h4>
        <div>
          <Button className="btn btn-md mx-2" color="success" onClick={addNewRow}>+</Button>
        </div>
      </div>
      <FormGroup>
        <Table>
          <thead>
            <tr>
              <th>
                {props.t("Client Product")}
                {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_09")} props={props} /> */}
              </th>
              <th>{props.t("Container No")}</th>
              <th>{props.t("Leverage Charge")}</th>
              <th>{props.t("Description")}</th>
              <th>{props.t("Product Count")}</th> 
              <th></th>
            </tr>
          </thead>
          <tbody>
            {updateInslugItemsData.map((item, index) => (
              <tr key={index}>
                <td>
                  {/* {hasViewPermission(permissions, "DE_09") ? */}
                  <DynamicCreatableSelect
                    name="client_product_id"
                    value={clientUpdateProducts.find(option => option.value === item.client_product_id)}
                    options={clientUpdateProducts.filter(product => product.clientNo === updateInslugData.client_no)} // Filtreleme işlemi
                    onChange={(selectedOption) => handleSelectChange(index, 'client_product_id', selectedOption)}
                    onCreateOption={(inputValue) => handleCreateOption(inputValue, index)}
                    placeholder={props.t("Select client product")}
                    noOptionsMessage={() => props.t("no-option-message")}
                    getOptionLabel={renderProductLabel} // Görsel olarak etiketi şekillendir
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    filterOption={(option, inputValue) => {
                      const productNo = option.data.productNo || "";
                      const productName = option.data.productName || "";
                      const productNoMatch = productNo.toLowerCase().includes(inputValue.toLowerCase());
                      const productNameMatch = productName.toLowerCase().includes(inputValue.toLowerCase());

                      return productNoMatch || productNameMatch;
                    }}
                  />
                  {/* :
                  <NoAuthUpdateInput data={clientUpdateProducts.find(option => option.value === item.client_product_id)?.productCategoryName} />
                } */}
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="container_no"
                    value={item.container_no}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter container no")}
                    required
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="leverage_charge"
                    value={item.leverage_charge}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter leverage charge information")}
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="description"
                    value={item.description}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter description")}
                    minLength={3}
                    maxLength={255}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="number"
                    id="count"
                    name="count"
                    value={item.count}
                    placeholder={props.t("Enter product count")}
                    onChange={(e) => handleCountChange(index, e)}
                  />
                </td>
                <td>
                  <div className='d-flex justify-content-center align-items-center'>
                  <Button className="btn btn-md my-2" color="danger" onClick={() => deleteIndexRow(index)}>-</Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </FormGroup>
    </Card>
  );
};

UpdateInslugItem.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateInslugItem)