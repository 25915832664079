const { default: DynamicInput } = require("components/Common/Form/DynamicInput");

const NoAuthUpdateInput = ({ data }) => {
    return <DynamicInput
        type="text"
        name="noAuth"
        id="noAuth"
        value={data}
        readOnly={true}
    />
};

export default NoAuthUpdateInput;