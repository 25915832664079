import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setCountries } from '../../../store/Definitions/country/action'; // Redux actions
import { openModal, closeModal } from '../../../store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from '../../../components/Common/Modals/acceptModal'; // AcceptModal import
import { deleteCountry, getCountries } from '../../../service/Definitions/countryService';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import UpdateCountryForm from './updateCountryForm';
import { setCountryFormData } from 'store/updates/countryForm/action';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import * as XLSX from 'xlsx'; // Excel export için
import excel from "../../../assets/icons/excel-white.png";
import { standartDateTime } from 'components/Common/dateConverter';
import EditButton from 'components/Common/Button/editButton';
import DeleteButton from 'components/Common/Button/deleteButton';

const CountriesList = (props) => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const formData = useSelector((state) => state.countryFormData?.formData || {});
  const [searchText, setSearchText] = useState(''); // State for search text
  const [filteredCountries, setFilteredCountries] = useState([]);

  const countries = useSelector((state) => state.countries?.countries || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryList = await getCountries(); // Fetch countries from API
        dispatch(setCountries(countryList));  // Dispatch to Redux store
        setFilteredCountries(countryList);
      } catch (error) {
        console.error('Error fetching countries:', error);
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch, props]);

  useEffect(() => {
    const filteredData = countries.filter((country) =>
      country.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"))
    );
    setFilteredCountries(filteredData);
  }, [searchText, countries]);


  useEffect(() => {
    console.log("countryList - formData");
    console.log(formData);
  }, [formData]);

  // Handle editing row and open modal
  const handleEdit = (id) => {
    const selectedData = filteredCountries.find((data) => data.id === id);
    //dispatch(setFormData(selectedData)); // Set the form data for the current row
    dispatch(setCountryFormData(selectedData));
    // Open modal for editing
    dispatch(
      openUpdateModal({
        body: (
          <UpdateCountryForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteCountry(id); // Delete country
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });

            const updatedCountries = await getCountries(); // Fetch updated countries
            dispatch(setCountries(updatedCountries)); // Update Redux state
            setFilteredCountries(updatedCountries); // Update filtered countries
            dispatch(closeModal()); // Close the modal after successful update
          } catch (error) {
            console.error('Error deleting country:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        },
      })
    );
  };

  const exportToExcel = () => {

    const column1Name = props.t("id");
    const column2Name = props.t("Country");
    const column3Name = props.t("Created At");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredCountries.map(country => ({
      [column1Name]: country.id, // 1. sütun verisi
      [column2Name]: country.name, // 2. sütun verisi
      [column3Name]: standartDateTime(country.createdAt), // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Countries"));
    XLSX.writeFile(workbook, props.t("Countries") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("#"),
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Name"),
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row) => (
        <>
        {hasUpdatePermission(permissions, "DE_11") ? <EditButton disabled={false} onClick={() => handleEdit(row.id)} /> : <EditButton disabled={true} />}
        {hasDeletePermission(permissions, "DE_11") ? <DeleteButton disabled={false} onClick={() => handleDelete(row.id)} /> : <DeleteButton disabled={true} />}
      </>
      ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("Country List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredCountries}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_11") ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} // Search input update
              style={{ width: '250px' }} // Smaller input field
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

CountriesList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(CountriesList);
