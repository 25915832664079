import React, { useState } from 'react';
import { Input } from 'reactstrap';

const DynamicInput = ({
  type = "text", // Varsayılan input tipi 'text' olacak
  value = '',
  name = '',
  id = '',
  onChange = () => {},
  onClick = () => {},
  readOnly = false,
  disabled = false,
  maxLength = null,
  minLength = null,
  placeholder = 'Lütfen bir değer girin',
  className = 'rounded-lg shadow bg-white p-3 fs-6', // Varsayılan className
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false); // Hover durumu için state

  return (
    <Input
      type={type}
      value={value}
      name={name}
      id={id}
      style={{
        border: isHovered ? '2px solid #7469B6' : '2px solid #E1AFD1',  // Hover durumuna göre border rengi
        borderRadius: '8px',
        transition: 'border-color 0.3s ease', // Geçişi yumuşatmak için
      }}
      onMouseEnter={() => setIsHovered(true)}  // Hover durumu aktif
      onMouseLeave={() => setIsHovered(false)} // Hover durumu pasif
      onChange={onChange}
      onClick={onClick}
      readOnly={readOnly}
      maxLength={maxLength}
      minLength={minLength}
      disabled={disabled}
      placeholder={placeholder}
      className={className} // Varsayılan veya dışarıdan gelen className
      {...rest} // Diğer eklenebilecek props'lar
    />
  );
};

export default DynamicInput;
