import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { useSelector } from 'react-redux';
import { createUser, getUsers } from 'service/userService';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import { setUserListFormData } from 'store/userList/action';
import PhoneInput from 'react-phone-number-input'
import { setUserFormData } from 'store/updates/userForm/action';
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import NoAuthText from 'components/noAuth/noAuthText';
import { hasViewPermission } from 'service/common/permissionService';
import NoAuthInput from 'components/noAuth/noAuthCreateInput';

const UserForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const md = 6;
  const xl = 4;
  //const formData = useSelector((state) => state.countryFormData?.formData || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await getUsers();
        console.log("getUsersssss");
        console.log(usersData)
        setRoles(usersData.roles);
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: inputData,
    });
  };

  const handleChangeMail = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: inputData,
    });
  };

  const handlePhone = (value) => {
    console.log("value");
    console.log(value);
    setFormData({
      ...formData,
      ["phone"]: value,
    });
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm()
  };

  const handleConfirm = async () => {
    console.log("formDataaaa");
    console.log(formData);
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          dispatch(closeModal()); 
          try {
            await createUser(formData)
              .then(async () => {
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const users = await getUsers(); 
                dispatch(setUserListFormData(users.users));
                setSelectedRole(null)
                setFormData({});
              })

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
          }
        }
      })
    );

  };

  const handleSelectChange = (field, selected) => {
    console.log(selected);
    setSelectedRole(selected);
    if (selected) {
      setFormData({ ...formData, [field]: selected.value });
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h4 className="text-primary">{props.t("Add User")}</h4>
          <Button className='text-white' color="success" type='submit'>
            {props.t("Save")}
          </Button>
        </div>
        <Row className='mb-4' form>
        <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="role_id">{props.t("Role")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "MM_02")} props={props} />
              {hasViewPermission(permissions, "MM_02") ? */}
                <DynamicSelect
                  options={roles.map((country) => ({
                    value: country.role_id,  // value'da client_no kullanıyoruz
                    label: country.name,  // label'da company.name kullanıyoruz
                  }))}
                  value={selectedRole ? selectedRole : null}
                  onChange={(selected) => handleSelectChange("role_id", selected)} // Kategori seçimi
                  placeholder={props.t("Select role")}
                  noOptionsMessage={() => props.t("no-option-message")}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  isClearable
                  required
                  filterOption={(option, inputValue) => {
                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                    return companyNameMatch;
                  }}
                />
                {/* :
                <NoAuthInput placeholder={props.t("Select role")} />
              } */}
            </FormGroup>
          </Col>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="name">{props.t("Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="surname">{props.t("Surname")}</Label>
              <DynamicInput
                type="text"
                name="surname"
                id="surname"
                value={formData.surname}
                onChange={handleChange}
                placeholder={props.t("Enter surname")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="phone">{props.t("Phone")}</Label>
              <PhoneInput
                className='custom-phone-input bg-white fs-6'
                placeholder={props.t("Enter phone number")}
                value={formData.phone}
                onMouseEnter={() => setIsHovered(true)}  // Hover durumu aktif
                onMouseLeave={() => setIsHovered(false)} // Hover durumu pasif
                onChange={value => handlePhone(value)} 
                minLength={6}
                maxLength={24}
              />
            </FormGroup>
          </Col>
          <Col md={md} xl={xl}>
            <FormGroup>
              <Label for="email">{props.t("Email")}</Label>
              <DynamicInput
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChangeMail}
                placeholder={props.t("Enter mail address")}
                required
                minLength={3}
                maxLength={64}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

UserForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UserForm)