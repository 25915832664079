import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setCities } from '../../../store/Definitions/city/action';
import { getCities, createCity } from '../../../service/Definitions/cityService';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { closeModal, openModal } from 'store/modal/actions';
import { setCountries } from 'store/Definitions/country/action';
import NoAuthText from 'components/noAuth/noAuthText';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import { useSelector } from 'react-redux';
import NoAuthInput from 'components/noAuth/noAuthCreateInput';

const CityForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  // Form state, JSON yapısına göre düzenlendi
  const [formData, setFormData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {

    const fetchData = async () => {
      try {
        const countryList = await getCountries();
        console.log(countryList)
        setCountryList(countryList)
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);


  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.toLocaleUpperCase("tr"),
    });
  };

  const handleSelectChange = (field, selected) => {
    setSelectedCountry(selected)
    if (selected) {
      setFormData({ ...formData, [field]: selected.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          try {
            await createCity(formData)
              .then(async () => {
                dispatch(closeModal()); // Close the modal after successful update
                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                const countries = await getCities(); // Ürünleri güncelle
                dispatch(setCities(countries)); // Redux state güncelleme
                setSelectedCountry(null);
                setFormData({});
              })

          } catch (error) {
            console.error("Ürün grubu eklenemedi:", error);
            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after successful update
          }
        }
      })
    );
  };

  const handleCreateCountry = (inputValue) => {
    if(!hasCreatePermission(permissions, "DE_11")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span>{props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newCountry = {
            name: inputValue,  // Kullanıcının girdiği değer
          };
          await createCountry(newCountry)
            .then(async (id) => {
              const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
              console.log(newCountryList);
              const newSelected = newCountryList.find(data => data.id === id);
              console.log("newSelecteeeddd");
              console.log(newSelected);
              const formattedCountries = newCountryList.map(country => ({
                value: country.id,
                label: country.name
              }));
              setCountryList(formattedCountries);
              dispatch(setCountries(formattedCountries));
              setSelectedCountry({ value: newSelected.id, label: newSelected.name });
              setFormData({ ...formData, ["country_id"]: newSelected.id });
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              console.log(error);
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Add City")}</h5>
          <Button className='text-white' color="success" type='submit'>
            {props.t("Save")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="country">{props.t("Country")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_11")} props={props} />
              {hasViewPermission(permissions, "DE_11") ? */}
                <DynamicCreatableSelect
                  options={countryList.map((country) => ({
                    value: country.id,  // value'da client_no kullanıyoruz
                    label: country.name,  // label'da company.name kullanıyoruz
                  }))}
                  value={selectedCountry ? selectedCountry : null}
                  onChange={(selected) => handleSelectChange("country_id", selected)} // Kategori seçimi
                  onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                  placeholder={props.t("Select country")}
                  noOptionsMessage={() => props.t("no-option-message")}
                  onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                  isClearable
                  required
                  formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                  filterOption={(option, inputValue) => {
                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                    return companyNameMatch;
                  }}
                />
               {/*  :
                <NoAuthInput placeholder={props.t("Enter country")} />
              } */}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("City Name")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter city name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

CityForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(CityForm)