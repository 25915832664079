import axios from 'axios';
// API URLs
import { API_BASE_URL, API_DEPOT} from "config";

import { getWithToken, postWithToken } from './common/tokenService';
const API_COMPANY = '/company';

const API_COMPANY_CREATE = '/create';
const API_COMPANY_GET = '/get';
const API_COMPANY_GET_DELETED = '/get-deleted';
const API_COMPANY_UPDATE = '/update';
const API_COMPANY_DELETE = '/delete';
const API_COMPANY_RESTORE = '/restore';

const API_EXTERN_COMPANY = '/extern-company';
const API_EXTERN_COMPANY_CREATE = '/create';
const API_EXTERN_COMPANY_GET = '/get';
const API_EXTERN_COMPANY_GET_DELETED = '/get-deleted';

const API_EXTERN_COMPANY_UPDATE = '/update';
const API_EXTERN_COMPANY_DELETE = '/delete';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} companyData - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createCompany = async (companyData) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_COMPANY}${API_COMPANY_CREATE}`, companyData);
    console.log("createCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

export const createExternCompany = async (companyData) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_EXTERN_COMPANY}${API_EXTERN_COMPANY_CREATE}`, companyData);
    console.log("createExternCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getCompanies = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_COMPANY}${API_COMPANY_GET}`);
      console.log("burası getCompanies service");
      console.log(response.data);
      console.log("*******");
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedCompanies = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_COMPANY}${API_COMPANY_GET_DELETED}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getExternCompanies = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_EXTERN_COMPANY}${API_EXTERN_COMPANY_GET}`);
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedExternCompanies = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_EXTERN_COMPANY}${API_EXTERN_COMPANY_GET_DELETED}`);
    console.log("extern companies response dataa");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateCompany = async (data) => {
  try {
    console.log ("comapnyData");
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_COMPANY}${API_COMPANY_UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateExternCompany = async (data) => {
  try {
    console.log ("comapnyData");
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_EXTERN_COMPANY}${API_EXTERN_COMPANY_UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteCompany = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_COMPANY}${API_COMPANY_DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreCompany = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_COMPANY}${API_COMPANY_RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteExternCompany = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_EXTERN_COMPANY}${API_EXTERN_COMPANY_DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreExternCompany = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_EXTERN_COMPANY}${API_COMPANY_RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};