import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label, Row, Col, Input, Form } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { setRoles } from '../../../store/settings/roles/action';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import DataTable from 'react-data-table-component';
import customStyles from 'styles/customDatatableStyle';
import { createNewRole, getModulsAndPermission, getRolePermission, getRoles, updateRole } from 'service/settings/rolesService';
import { setFilteredRoles } from 'store/settings/filteredRoles/action';
import { closeUpdateModal } from 'store/updateModal/actions';

const UpdateRolesForm = props => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [filteredRoleList, setFilteredRoleList] = useState([]);
  const [data, setData] = useState([]); // initialData, datatable'da kullanacağınız veridir
  const [selectedData, setSelectedData] = useState([]); // initialData, datatable'da kullanacağınız veridir
  const [formSearchText, setFormSearchText] = useState('');
  const [permissionsForm, setPermissionsForm] = useState();


  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    setFormData({
      ...formData,
      [e.target.name]: inputData,
    });
  };

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const permissions = await getModulsAndPermission();
        //dispatch(setRoles(rolesList));  
        setPermissionsForm(permissions);
        setFilteredRoleList(permissions);

        const selectedPermissions = await getRolePermission(props.selectedId);
        console.log("selectedPermissions");
        console.log(selectedPermissions);
        setSelectedData(selectedPermissions);
        setFormData({
          ...formData,
          ["name"]: selectedPermissions[0].role_name,
          ["role_id"]: selectedPermissions[0].role_id,
        });
      } catch (error) {
        console.error('Error fetching countries:', error);
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch, props]);

  // Arama işlevi için useEffect
  useEffect(() => {
    if (formSearchText.trim() === "") {
      // Eğer arama kutusu boşsa tüm izinleri göster
      setFilteredRoleList((prev) => ({ ...prev, allPermission: permissionsForm?.allPermission || [] }));
    } else {
      // Arama metnine göre allPermission'ı filtrele
      const filteredData = permissionsForm?.allPermission?.filter((item) =>
        item.name?.toLocaleUpperCase("tr").includes(formSearchText.toLocaleUpperCase("tr"))
      );
      setFilteredRoleList((prev) => ({ ...prev, allPermission: filteredData }));
    }
  }, [formSearchText, permissionsForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  function hasPermission(array) {
    console.log("haspermission-arrayy");
    console.log(array);
    // Eğer dizi boşsa false döndür
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }

    // Her bir elemanı kontrol et, permissions true ise true döndür
    for (let item of array) {
      if (item.permission === true) {
        return true;
      }
    }

    // Eğer permissions hiçbir elemanda true değilse false döndür
    return false;
  }

  const handleConfirm = async () => {
    console.log("confirm iç");
    console.log(formData);
    if (formData.name === "") {
      toast.error(props.t("Role can not be an empty"), { position: "top-right" });
    } else {
      if (!hasPermission(selectedData)) {
        toast.error(props.t("Please select minimum one permission"), { position: "top-right" });
      }
      else {
        dispatch(
          openModal({
            title: props.t("modal-save-title"),
            body: props.t("modal-save-body"),
            onConfirm: async () => {
              dispatch(closeModal()); // Close the modal after successful update
              try {
                const newData = { ...formData, permissions: selectedData };
                console.log("YYYYYYYYYYYYYYYYYYY");
                console.log(newData);
                await updateRole(newData)
                  .then(async () => {
                    toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                    const rolesList = await getRoles(); // Fetch countries from API
                    dispatch(setRoles(rolesList));  // Dispatch to Redux store
                    dispatch(setFilteredRoles(rolesList));
                  })
                dispatch(closeUpdateModal()); // Close the modal after successful update

              } catch (error) {
                console.error("Ürün grubu eklenemedi:", error);
                toast.error(props.t("toast-error-update-data"), { position: "top-right" });
              }
            }
          })
        );
      }
    }

  };


  const handleCheckboxChange = (event, moduleNo, activityNo) => {
    const newData = {
      module_no: moduleNo,
      activity_no: activityNo,
      permission: event.target.checked
    };

    // `data` dizisinin kopyasını oluşturun
    let updatedData = [...data];
    const existingIndex = updatedData.findIndex(item => item.module_no === moduleNo && item.activity_no === activityNo);

    if (existingIndex !== -1) {
      // Var olan bir öğeyi güncelle
      updatedData[existingIndex] = newData;
    } else {
      // Yeni bir öğe ekle
      updatedData.push(newData);
    }

    // Güncellenmiş veriyi `setData` ile kaydedin
    setData(updatedData);

    // Aynı işlemi `selectedData` için de yapın
    let updatedSelectedData = [...selectedData];
    const selectedIndex = updatedSelectedData.findIndex(item => item.module_no === moduleNo && item.activity_no === activityNo);

    if (selectedIndex !== -1) {
      updatedSelectedData[selectedIndex] = newData;
    } else {
      updatedSelectedData.push(newData);
    }

    setSelectedData(updatedSelectedData);

    console.log("Updated Data:", updatedData);
  };

  useEffect(() => {
    console.log("data değeri güncellendi");
    console.log(data);
  }, [data]);

  useEffect(() => {
    console.log("seletectedData");
    console.log(selectedData);
  }, [selectedData]);

  const toggleRowSelection = (row, isChecked) => {
    const updatedRowData = row.activityies.map((item) => ({
      module_no: row.module_no,
      activity_no: item.activity_no,
      permission: isChecked,
    }));

    const newData = isChecked
      ? [...data.filter(d => d.module_no !== row.module_no), ...updatedRowData]
      : data.filter(d => d.module_no !== row.module_no);

    setData((prevData) => [...prevData.filter(d => d.module_no !== row.module_no), ...updatedRowData]);
    setSelectedData((prevSelected) => [...prevSelected.filter(d => d.module_no !== row.module_no), ...updatedRowData]);
  };

  const columName = (activities) => {
    const columnArray = [];

    let selectAllColumn = {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={(e) => toggleRowSelection(row, e.target.checked)}
          checked={row.activityies.every((item) =>
            selectedData.some((selectedItem) =>
              selectedItem.module_no === row.module_no &&
              selectedItem.activity_no === item.activity_no &&
              selectedItem.permission
            )
          )}
        />
      ),
      sortable: false,
      width: "50px",
      wrap: true,
    };

    columnArray.push(selectAllColumn); // En sola ekliyoruz


    let column = {
      selector: (row) => props.t(row.name),
      sortable: true,
      wrap: true,
      cell: (row) => (
        <span style={{ fontWeight: 'bold', color: '#94505F' }}> {/* Renk ve kalınlık ayarlandı */}
          {row.name}
        </span>
      ),
    };

    columnArray.push(column);

    activities?.forEach((element) => {
      let column = {
        name: element.name,
        selector: (row) =>
          row.activityies.map((item, index) =>
            item.activity_no === element.activity_no ? (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", marginRight: "10px" }}
              >
                <label className="mb-0">{props.t(item.activity_no)}</label>
                <input
                  className="mb-0 ms-2"
                  type="checkbox"
                  checked={
                    selectedData.some(
                      (data) =>
                        data.activity_no === item.activity_no &&
                        data.module_no === row.module_no &&
                        data.permission
                    ) || false
                  }
                  onChange={(e) => handleCheckboxChange(e, row.module_no, item.activity_no)}
                />
              </div>
            ) : null
          ),
        sortable: true,
        wrap: true,
      };
      columnArray.push(column);
    });

    return columnArray;
  };


  const allActivitiesList = columName(filteredRoleList.allActivities);

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Update Role")}</h5>
          <Button color="warning" type='submit'>
            {props.t("Update")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="name">{props.t("New Role")}</Label>
              <DynamicInput
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={props.t("Enter role name")}
                required
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
          <DataTable
            className='p-3'
            columns={allActivitiesList}
            data={filteredRoleList.allPermission}
            customStyles={customStyles}
            noDataComponent={props.t("table-empty-message")}
            paginationComponentOptions={customPaginationOptions}
            pagination
            highlightOnHover
            subHeader
            subHeaderComponent={
              <div className='d-flex justify-content-end w-50'>
                <Input
                  type="text"
                  placeholder={props.t("Search")}
                  value={formSearchText}
                  onChange={(e) => setFormSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
                  style={{ width: '250px' }} // Smaller input field
                />
              </div>
            }
          />
        </Row>
      </Form>
    </div>
  );
};

UpdateRolesForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateRolesForm)