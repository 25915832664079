// ToastMessage.js
import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

const ToastMessage = ({ title, content, toastOptions }) => {

    const CloseButton = ({ closeToast }) => (
        <FaTimes style={{ cursor: 'pointer', color: 'purple', fontSize: '16px' }} onClick={closeToast} />
    );

    return toast(
        <div>
            {title}
            {content}
        </div>,
        {
            position: "top-left",
            autoClose: false,
            closeOnClick: true,
            draggable: true,
            pauseOnHover: true,
            style: { backgroundColor: '#FEFAF6' },
            className: "shadow shadow-lg custom-toast-info",
            closeButton: <CloseButton />,
            ...toastOptions,  // Ek ayarları geçersiz kılmak için
        }
    );
};

ToastMessage.propTypes = {
    title: PropTypes.any,
    content: PropTypes.any,  // İçeriği `node` olarak alıyoruz, böylece JSX verebiliriz
    toastOptions: PropTypes.object,
};

export default ToastMessage;
