import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { FaRecycle, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // PDF export için
import * as XLSX from 'xlsx'; // Excel export için
import { deleteCity, getCities, getDeletedCities } from 'service/Definitions/cityService';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import excel from "assets/icons/excel-white.png";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { getCountries } from 'service/Definitions/countryService';
import { hasDeletePermission, hasExcelPermission } from 'service/common/permissionService';
import { standartDateTime } from 'components/Common/dateConverter';
import RestoreButton from 'components/Common/Button/restoreButton';


const TrashedCities = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [filteredCities, setFilteredCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const cityList = await getDeletedCities(); // Fetch companies from API
        console.log(cityList);
        setCities(cityList);  // Dispatch to Redux store
        setFilteredCities(cityList);
        console.log("******");
        console.log(cityList);
      } catch (error) {
        console.error('Error fetching companies:', error);
        //toast.error("toast-error-fetch-data", { position: "top-right" });
      }
    };

    const fetchCountryData = async () => {
      try {
        const countryList = await getCountries();
        console.log(countries)
        setCountries(countryList)
      } catch (error) {
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
    fetchCountryData();
  }, [dispatch]);


  useEffect(() => {
    const filteredData = cities.filter((city) =>
      city.name?.includes(searchText) ||
      city.country_name?.includes(searchText)
    );
    setFilteredCities(filteredData);
  }, [searchText, cities]);

  const handleRestore = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await deleteCity(id); // Delete company
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });
            const newCities = await getCities(); // Fetch updated companies
            const newDeletedCities = await getDeletedCities(); // Fetch updated companies

            setCities(newDeletedCities); // Update Redux state
            setFilteredCities(newDeletedCities); // Update filtered companies
            dispatch(setCities(newCities))
            setSearchText('');
          } catch (error) {
            console.error('There was a problem deleting the product group.', error);
            toast.error(props.t("toast-success-restore-data"), { position: "top-right" });
          }
        }
      })
    );
  };

  const exportToExcel = () => {

    const column1Name = props.t("id");
    const column2Name = props.t("Country");
    const column3Name = props.t("City");
    const column4Name = props.t("Created At");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredCities.map(country => ({
      [column1Name]: country.id, // 1. sütun verisi
      [column2Name]: country.country_name, // 2. sütun verisi
      [column3Name]: country.name, // 2. sütun verisi
      [column4Name]: standartDateTime(country.createdAt), // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("cities"));
    XLSX.writeFile(workbook, props.t("cities") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const restoreIcon = (row) => <RestoreButton onClick={() => handleRestore(row.id)} />

  const columns = [
    {
      name: props.t("#"),
      selector: (row, rowIndex) => row.id,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Country"),
      selector: (row, rowIndex) => row.country_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("City"),
      selector: (row, rowIndex) => row.name,
      sortable: true,
      wrap: true,

    },
    {
      name: props.t("Actions"),
      cell: (row, rowIndex) =>
        <>
          {hasDeletePermission(permissions, "DE_12") ? restoreIcon(row) : null}
        </>
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("City List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredCities}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "DE_12") ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
            />
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

TrashedCities.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(TrashedCities)