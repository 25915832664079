import axios from 'axios';
// API URLs
import { API_BASE_URL, API_DEPOT } from "config";
import { getWithToken, postWithToken } from './common/tokenService';
const API_PRODUCT_GROUP = '/product-category';
const API_PRODUCT_GROUP_CREATE = '/create';
const API_PRODUCT_GROUP_GET = '/get';
const API_PRODUCT_GROUP_GET_DELETED = '/get-deleted';
const API_PRODUCT_GROUP_RESTORE = '/restore';
const API_PRODUCT_GROUP_UPDATE = '/update';
const API_PRODUCT_GROUP_DELETE = '/delete';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} companyData - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createProductGroup = async (productData) => {
  console.log("servis iç");
  console.log(productData);
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PRODUCT_GROUP}${API_PRODUCT_GROUP_CREATE}`, productData);
    console.log("createCompany");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getProductGroup = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_PRODUCT_GROUP}${API_PRODUCT_GROUP_GET}`);
      console.log("burası getCompanies service");
      console.log(response.data);
      console.log("*******");
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getDeletedProductGroup = async () => {
  try {
    const response = await getWithToken(`${API_BASE_URL}${API_DEPOT}${API_PRODUCT_GROUP}${API_PRODUCT_GROUP_GET_DELETED}`);
    console.log("burası getCompanies service");
    console.log(response.data);
    console.log("*******");
    return response.data;
  } catch (error) {
    console.error('Şirketler listelenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateProductGroup = async (data) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PRODUCT_GROUP}${API_PRODUCT_GROUP_UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteProductGroup = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PRODUCT_GROUP}${API_PRODUCT_GROUP_DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} companyId - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const restoreProductGroup = async (id) => {
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_DEPOT}${API_PRODUCT_GROUP}${API_PRODUCT_GROUP_RESTORE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};