import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { closeModal, openModal } from 'store/modal/actions';
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { clearUserFormData, setUserFormData } from 'store/updates/userForm/action';
import { getUsers, updateUser } from 'service/userService';
import { setUserListFormData } from 'store/userList/action';
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import PhoneInput from 'react-phone-number-input'
import DynamicSelect from 'components/Common/Form/DynamicSelect';

const UpdateUserForm = props => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.updateUserFormData?.formData || {});
    const [isHovered, setIsHovered] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState();

    useEffect(() => {
        console.log("updateUserFormData");
        console.log(formData);
        const fetchData = async () => {
            try {
                const usersData = await getUsers();
                console.log("getUsersssss");
                console.log(usersData)
                setRoles(usersData.roles);
                const selectedRoleData = usersData.roles.find(data => data.role_id === formData.role_id);
                console.log("selectedData");
                console.log(selectedRoleData);
                setSelectedRole({ value: selectedRoleData.role_id, label: selectedRoleData.name });
            } catch (error) {
                console.log(error);
                //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
            }
        };
        fetchData();
    }, [dispatch]);

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setUserFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
        console.log("update handleChane formData");
        console.log(formData);
    };

    const handleSelectChange = (field, selected) => {
        console.log(selected);
        setSelectedRole(selected);
        if (selected) {
            dispatch(setUserFormData({ ...formData, [field]: selected.value }));
        }
    };

    useEffect(() => {
        console.log("Formdata güncellendi");
        console.log(formData);
    }, [formData])

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = async () => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    const { createdAt, updatedAt, is_passive, role_name, ...rest } = formData;
                    console.log("reeesssttt");
                    console.log(rest);
                    try {
                        await updateUser(rest)
                            .then(async () => {
                                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                                dispatch(closeUpdateModal());
                                const usersData = await getUsers(); // Ürünleri güncelle
                                dispatch(setUserListFormData(usersData.users)); // Redux state güncelleme
                                dispatch(clearUserFormData());
                            })
                    } catch (error) {
                        console.error("Ürün grubu eklenemedi:", error);
                        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                    }
                }
            })
        );

    };

    const handleChangeMail = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        dispatch(setUserFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    const handlePhone = (value) => {
        console.log("value");
        console.log(value);
        dispatch(setUserFormData({
            ...formData,
            ["phone"]: value,
        }));
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update User")}</h5>
                    <Button color="warning" type='submit'>
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="name">{props.t("Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter name")}
                                required
                                minLength={3}
                                maxLength={32}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="surname">{props.t("Surname")}</Label>
                            <DynamicInput
                                type="text"
                                name="surname"
                                id="surname"
                                value={formData.surname}
                                onChange={handleChange}
                                placeholder={props.t("Enter surname")}
                                required
                                minLength={3}
                                maxLength={32}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="phone">{props.t("Phone")}</Label>
                            <PhoneInput
                                className='custom-phone-input bg-white fs-6'
                                placeholder={props.t("Enter phone number")}
                                value={formData.phone}
                                onMouseEnter={() => setIsHovered(true)}  // Hover durumu aktif
                                onMouseLeave={() => setIsHovered(false)} // Hover durumu pasif
                                onChange={value => handlePhone(value)} 
                                minLength={6}
                                maxLength={24}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="email">{props.t("Email")}</Label>
                            <DynamicInput
                                type="text"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChangeMail}
                                placeholder={props.t("Enter mail address")}
                                required
                                minLength={3}
                                maxLength={64}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="role_id">{props.t("Role")}</Label>
                            <DynamicSelect
                                options={roles.map((country) => ({
                                    value: country.role_id,  // value'da client_no kullanıyoruz
                                    label: country.name,  // label'da company.name kullanıyoruz
                                }))}
                                value={selectedRole ? selectedRole : null}
                                onChange={(selected) => handleSelectChange("role_id", selected)} // Kategori seçimi
                                placeholder={props.t("Select role")}
                                noOptionsMessage={() => props.t("no-option-message")}
                                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                                isClearable
                                required
                                filterOption={(option, inputValue) => {
                                    // Hem client_no hem de company.name üzerinden arama yapabilmek için
                                    const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                                    return companyNameMatch;
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

UpdateUserForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(UpdateUserForm)