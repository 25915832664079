import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Card } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setInslugData } from '../../../../store/depot/inslug/actions';
import { getInslugs, inslugInfo } from '../../../../service/inslugService';
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { createCompany, createExternCompany } from 'service/companyService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { openModal, closeModal } from '../../../../store/modal/actions'; // Modal actions
import showFilteredListInToast from 'components/Common/Toasts/infoToast';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import InslugActions from './inslugActions';
import { setSelectedProducts } from 'store/depot/selectedProducts/actions';
import { setInslugItemData } from 'store/depot/inslugItem/actions';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import NoAuthText from 'components/noAuth/noAuthText';
import NoAuthInput from 'components/noAuth/noAuthCreateInput';

const AddInslug = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);

  // Redux state
  const newInslug = useSelector((state) => state.inslug);
  const selectedProducts = useSelector((state) => state.selectedProducts?.selectedProducts || []);

  // Local state
  const [companies, setCompanies] = React.useState([]);
  const [externCompanies, setExternCompanies] = React.useState([]);
  const [customsStatuses, setCustomsStatuses] = React.useState([]);
  const [carrierTypes, setCarrierTypes] = React.useState([]);
  const [loadingStatuses, setLoadingStatuses] = React.useState([]);
  const [processStatuses, setProcessStatuses] = React.useState([]);
  const [defaultDate, setDefaultDate] = useState("");
  const [inslugList, setInslugList] = useState([]);
  const [selectedClientInslugs, setSelectedClientInslugs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingInputValue, setPendingInputValue] = useState(null); // Modal onayı beklenen input değeri
  const md = 6;
  const lg = 4;
  const xl = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inslugInfos = await inslugInfo();
        console.log("inslugInfo");
        console.log(inslugInfos);
        setCompanies(inslugInfos.company);
        setExternCompanies(inslugInfos.externCompany);
        setCustomsStatuses(inslugInfos.customsStatus);
        setCarrierTypes(inslugInfos.carrierType);
        setLoadingStatuses(inslugInfos.loadingStatus);
        setProcessStatuses(inslugInfos.processStatus);

      } catch (error) {
        console.log("hata" + error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };

    const fetchInslugData = async () => {
      try {
        const inslugs = await getInslugs();
        console.log("inslugList");
        console.log(inslugs);
        setInslugList(inslugs);
      } catch (error) {
      }
    };

    fetchInslugData();
    fetchData();
  }, [dispatch]);

  const toggleConfirmationModal = () => {
    setModalOpen(!modalOpen); // Modal açma/kapatma işlemi
  };

  useEffect(() => {
    // Fetch data asynchronously
    console.log("Redux çalıştı");
    console.log(newInslug);
  }, [newInslug]);

  useEffect(() => {
    // Günün tarihini al ve 'YYYY-MM-DD' formatına getir
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD' formatında bir string
    setDefaultDate(formattedDate);
    handleDate({ 'name': 'booking_date', 'value': formattedDate })
  }, []);

  // Handle input changes and dispatch actions to update Redux state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setInslugData({ [name]: value.toLocaleUpperCase("tr") }));
    console.log(newInslug);
  };

  // Handle input changes and dispatch actions to update Redux state
  const handleCountChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    const name = e.target.name;
    dispatch(setInslugData({ [name]: value }));
  };

  const confirmCreateOption = () => {
    toggleConfirmationModal(); // Modalı kapat
    handleCreateOption(pendingInputValue); // Onay sonrası handleCreateOption çalıştır
  };

  const handleCreateWithConfirmation = (inputValue) => {
    if (!hasCreatePermission(permissions, "DE_01")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          handleCreateOption(inputValue);
          dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  };

  // Handle input changes and dispatch actions to update Redux state
  const handleDate = (e) => {
    const { name, value } = e;
    dispatch(setInslugData({ [name]: value }));
    console.log(newInslug);
  };

  const handleSelectChange = (field, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("value : " + value);
    dispatch(setInslugData({ [field]: value }));
    console.log(newInslug);
  };

  const handleSelectChangeClientNo = (selectedOption) => {
    dispatch(setSelectedProducts(null));
    selectedProducts.forEach((element, index) => {
      dispatch(setInslugItemData(index, "client_product_id", null));
    });
    //dispatch(setInslugItemData(index, "client_product_id", selectedOption ? selectedOption.client_product_id : null));

    console.log("--------------------------------");
    console.log(selectedOption);
    if (selectedOption) {
      const value = selectedOption.value;
      const selectedClientInslugs = inslugList.filter(item => item.client_no === selectedOption.client_id);
      //setSelectedClient(value);  // selected_client'ı local state'e ekliyoruz
      dispatch(setInslugData({ client_no: selectedOption.client_id }));
      dispatch(setInslugData({ "selected_client": value }));
      setSelectedClientInslugs(selectedClientInslugs);
      showFilteredListInToast(selectedOption.client_id, selectedClientInslugs);
    } else {
      // Eğer seçilen değer temizlendiyse (null ise), client_no alanını temizle
      //setSelectedClient(null);
      dispatch(setInslugData({ client_no: null }));
      dispatch(setInslugData({ "selected_client": null }));

    }
  };


  const customOption = ({ client_no, company_name }) => (
    <div className="d-flex align-items-center">
      <small className="bg-info text-white text-sm rounded px-1 py-1">
        {client_no}
      </small>
      <small className="company-name px-2">{company_name}</small>
    </div>
  );

  const handleCreateExternCompany = (inputValue, isTransport) => {
    if (!hasCreatePermission(permissions, "DE_02")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          const newCompany = {
            name: inputValue, 
          };

          await createExternCompany(newCompany)
            .then(async (addedExternCompany) => {
              console.log("addedExternCompany");
              console.log(addedExternCompany);
              const inslugInfos = await inslugInfo(); 

              setExternCompanies(inslugInfos.externCompany);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });

              if (isTransport === 0) {
                dispatch(setInslugData({ transport_company_no: addedExternCompany.client_no }));
              } else {
                dispatch(setInslugData({ supplier_company_no: addedExternCompany.client_no }));
              }
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
            
        }
      }));
  }

  const handleCreateOption = async (inputValue) => {
    console.log("handlecreateoption");
    console.log(inputValue);
    const newCompany = {
      name: inputValue,
    };

    await createCompany(newCompany)
      .then(async (addedCompany) => {
        const inslugInfos = await inslugInfo();

        setCompanies(inslugInfos.company);
        toast.success(props.t("toast-success-save-data"), { position: "top-right" });
        const value = customOption({ client_no: addedCompany.client_no, company_name: addedCompany.name });
        dispatch(setInslugData({ "selected_client": value }));
        dispatch(setInslugData({ client_no: addedCompany.client_no }));
      })
      .catch(error => {
        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
      });

  };

  return (
    <Card className='p-4 rounded-lg'>
      <div className='inslug'>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className='d-flex text-primary align-items-center'>{props.t("Inslug Information")}</h5>
          <InslugActions />
        </div>
        <Form >
          <Row form>
            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="client_no">{props.t("Company No / Company Name")}</Label>
                  <DynamicCreatableSelect
                    name='client_no'
                    value={newInslug.selected_client ? { value: newInslug.selectedClient, label: newInslug.selected_client } : null}
                    options={companies.map((company) => ({
                      value: customOption({ client_no: company.client_no, company_name: company.name }),
                      label: customOption({ client_no: company.client_no, company_name: company.name }),
                      client_id: company.client_no,
                      company_name: company.name,
                    }))}
                    noOptionsMessage={() => props.t("no-option-message")}
                    onChange={(selected) => handleSelectChangeClientNo(selected)}
                    onCreateOption={(inputValue) => handleCreateWithConfirmation(inputValue)}  
                    placeholder={props.t("Company No / Company Name")}
                    formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    filterOption={(option, inputValue) => {
                      console.log("filterOption");
                      console.log(option);
                      const clientNo = option.data.client_id || option.value;  
                      const companyName = option.data.company_name || option.label;  

                      const clientNoMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());
                      const companyNameMatch = companyName.toLowerCase().includes(inputValue.toLowerCase());

                      return clientNoMatch || companyNameMatch;
                    }}
                  />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="transport_company_no">{props.t("Transport Company")}</Label>
                  <DynamicCreatableSelect
                    options={externCompanies.map((company) => ({
                      value: company.client_no,
                      label: company.name,
                    }))}
                    value={
                      externCompanies.find(
                        (company) => company.client_no === newInslug.transport_company_no
                      )
                        ? {
                          value: externCompanies.find(
                            (company) => company.client_no === newInslug.transport_company_no
                          ).client_no,
                          label: externCompanies.find(
                            (company) => company.client_no === newInslug.transport_company_no
                          ).name,
                        }
                        : null
                    }
                    onChange={(selected) => {
                      handleSelectChange('transport_company_no', selected);
                    }}
                    onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue, 0) }}
                    placeholder={props.t("Select transport company")}
                    isClearable
                    noOptionsMessage={() => props.t("no-option-message")}
                    formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    filterOption={(option, inputValue) => {
                      const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                      const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                      return clientNoMatch || companyNameMatch;
                    }}
                  />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="supplier_company_no">{props.t("Supplier Company")}</Label>
                {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_02")} props={props} />
                {hasViewPermission(permissions, "DE_02") ? */}
                  <DynamicCreatableSelect
                    options={externCompanies.map((company) => ({
                      value: company.client_no,
                      label: company.name,
                    }))}
                    value={
                      externCompanies.find(
                        (company) => company.client_no === newInslug.supplier_company_no // Eşleşen durumu buluyoruz
                      )
                        ? {
                          value: externCompanies.find(
                            (company) => company.client_no === newInslug.supplier_company_no
                          ).client_no, // value'da client_no kullanıyoruz
                          label: externCompanies.find(
                            (company) => company.client_no === newInslug.supplier_company_no
                          ).name, // label'da company.name kullanıyoruz
                        }
                        : null // Eğer eşleşme yoksa null döner
                    }
                    onChange={(selected) => {
                      /* console.log(selected); */
                      handleSelectChange('supplier_company_no', selected); // Seçilen client_no'yu işliyoruz
                    }}
                    onCreateOption={async (inputValue) => { handleCreateExternCompany(inputValue, 1) }}
                    placeholder={props.t("Select supplier company")}
                    isClearable
                    noOptionsMessage={() => props.t("no-option-message")}
                    formatCreateLabel={(inputValue) => `Yeni ekle: ${inputValue}`} // Yeni seçenek ekleme mesajı
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    filterOption={(option, inputValue) => {
                      // Hem client_no hem de company.name üzerinden arama yapabilmek için
                      const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                      const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                      return clientNoMatch || companyNameMatch;
                    }}
                  />
                  {/* :
                  <NoAuthInput placeholder={props.t("Company No / Company Name")} />
                } */}
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="agency_reference">{props.t("Agency Reference")}</Label>
                <DynamicInput
                  type="text"
                  id="agency_reference"
                  name="agency_reference"
                  value={newInslug.agency_reference}
                  placeholder={props.t("Enter agency reference")}
                  onChange={handleInputChange}
                  minLength={3}
                  maxLength={24}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="booking_date">{props.t("Booking Date")}</Label>
                <DynamicInput
                  type="date"
                  id="booking_date"
                  name="booking_date"
                  value={newInslug.booking_date || defaultDate} // Eğer Redux'ta başka bir tarih yoksa defaultDate kullan
                  onChange={handleInputChange}
                  onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç

                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="eta">{props.t("ETA")}</Label>
                <DynamicInput
                  type="date"
                  id="eta"
                  name="eta"
                  value={newInslug.eta}
                  onChange={handleInputChange}
                  onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="closing_out">{props.t("Closing Out")}</Label>
                <DynamicInput
                  type="date"
                  id="closing_out"
                  name="closing_out"
                  value={newInslug.closing_out}
                  onChange={handleInputChange}
                  onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç

                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="closing_in">{props.t("Closing In")}</Label>
                <DynamicInput
                  type="date"
                  id="closing_in"
                  name="closing_in"
                  value={newInslug.closing_in}
                  onChange={handleInputChange}
                  onClick={(e) => e.target.showPicker()} // Tıklanınca tarih seçiciyi aç

                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="document_no">{props.t("Document No")}</Label>
                <DynamicInput
                  type="text"
                  id="document_no"
                  name="document_no"
                  value={newInslug.document_no}
                  placeholder={props.t("Enter document no")}
                  onChange={handleInputChange}
                  minLength={3}
                  maxLength={24}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="extern_document_no">{props.t("Ext Document No")}</Label>
                <DynamicInput
                  type="text"
                  id="extern_document_no"
                  name="extern_document_no"
                  value={newInslug.extern_document_no}
                  placeholder={props.t("Enter ext document no")}
                  onChange={handleInputChange}
                  minLength={3}
                  maxLength={24}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="description">{props.t("Description")}</Label>
                <DynamicInput
                  type="text"
                  id="description"
                  name="description"
                  value={newInslug.description}
                  placeholder={props.t("Enter description")}
                  onChange={handleInputChange}
                  minLength={3}
                  maxLength={255}
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="customs_status_id">{props.t("Customs Status")}</Label>
                <DynamicSelect
                  options={customsStatuses.map((status) => ({
                    value: status.id,  // value, id değerini içerir
                    label: status.name, // label, name değerini içerir
                  }))}
                  value={
                    customsStatuses.find(
                      (status) => status.id === newInslug.customs_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: customsStatuses.find(
                          (status) => status.id === newInslug.customs_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: customsStatuses.find(
                          (status) => status.id === newInslug.customs_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) => {
                    console.log("onChange çalıştı");
                    console.log(selected);

                    handleSelectChange("customs_status_id", selected); // Seçilen id'yi işliyoruz
                  }}
                  placeholder={props.t("Select customs status")}
                  isClearable
                />

              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="carrier_type_id">{props.t("Carrier Type")}</Label>
                <DynamicSelect
                  options={carrierTypes.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                  }))}
                  value={
                    carrierTypes.find(
                      (status) => status.id === newInslug.carrier_type_id // Eşleşen durumu bul
                    )
                      ? {
                        value: carrierTypes.find(
                          (status) => status.id === newInslug.carrier_type_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: carrierTypes.find(
                          (status) => status.id === newInslug.carrier_type_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('carrier_type_id', selected)
                  }
                  placeholder={props.t("Select carrier type")}
                  isClearable
                />
              </FormGroup>
            </Col>

            <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="loading_status_id">{props.t("Loading Status")}</Label>
                <DynamicSelect
                  options={loadingStatuses.map((status) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  value={
                    loadingStatuses.find(
                      (status) => status.id === newInslug.loading_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: loadingStatuses.find(
                          (status) => status.id === newInslug.loading_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: loadingStatuses.find(
                          (status) => status.id === newInslug.loading_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('loading_status_id', selected)
                  }
                  placeholder={props.t("Select loading status")}
                  isClearable
                />
              </FormGroup>
            </Col>

            {/* <Col md={md} lg={lg} xl={xl}>
              <FormGroup>
                <Label for="process_status_id">{props.t("Process Status")}</Label>
                <DynamicSelect
                  options={processStatuses.map((status) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  value={
                    processStatuses.find(
                      (status) => status.id === newInslug.process_status_id // Eşleşen durumu bul
                    )
                      ? {
                        value: processStatuses.find(
                          (status) => status.id === newInslug.process_status_id
                        ).id, // value'da id değerini kullanıyoruz
                        label: processStatuses.find(
                          (status) => status.id === newInslug.process_status_id
                        ).name, // label'da name değerini kullanıyoruz
                      }
                      : null // Eğer eşleşme yoksa null döner, placeholder gösterilir
                  }
                  noOptionsMessage={() => props.t("no-option-message")}
                  onChange={(selected) =>
                    handleSelectChange('process_status_id', selected)
                  }
                  placeholder={props.t("Select process status")}
                  isClearable
                />
              </FormGroup>
            </Col> */}

            {/* <Col md={3}>
              <FormGroup>
                <Label for="count">{props.t("Count")}</Label>
                <DynamicInput
                  type="number"
                  id="count"
                  name="count"
                  value={newInslug.count}
                  placeholder={props.t("Enter container count")}
                  onChange={handleCountChange}
                />
              </FormGroup>
            </Col> */}
          </Row>
        </Form>
      </div>
      <AcceptModal />
    </Card>
  );
};

AddInslug.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(AddInslug)
