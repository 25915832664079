import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import '../../../styles/custom/toast-style.css';
function showFilteredListInToast(client_no, inslugList) {
  const filteredList = inslugList.filter(item => item.client_no === client_no);

  if (filteredList.length === 0) {
    return;
  }

  // DataTable için sütunlar (columns)
  const columns = [
    {
      name: 'Tarih',
      selector: row => row.booking_date ?  new Date(row.booking_date).toLocaleDateString() : "Belirtilmedi",
      sortable: true,
      width:"100px",
      wrap: true,
    },
    {
      name: 'Konteyner Sayısı',
      selector: row => row.count ? row.count : "Belirtilmemiş",
      sortable: true,
      width:"150px",
      wrap: true,
    }, 
  ];

  // DataTable bileşenini toast içinde render etme
  toast.info(
    <div style={{ width: 'fit-content' }}>
      <DataTable
        columns={columns}
        data={filteredList}
        noHeader
        pagination={false}
        customStyles={{
          table: {
            style: {
              border: 'none', // Border'ı kaldırıyoruz
            },
          },
          rows: {
            style: {
              borderBottom: 'none', // Satırlar arası border'ı kaldırıyoruz
            },
          },
        }}
      />
    </div>,
    { position: "top-left", autoClose: false , className: 'wide-toast', // Toast'ın genişliğini artıracak class
  }
  );
}


export default showFilteredListInToast;