import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import { useSelector } from 'react-redux';
import { hasViewPermission } from 'service/common/permissionService';
import DefinitionItemPage from './definitionItemPage';

const DefinitionItem = (props) => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };
  const { t } = useTranslation();
  document.title = t("Carrier Types");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  const definitionItemPage =<DefinitionItemPage refresh={refresh} />


  return (
    <div>
      {hasViewPermission(permissions, "DE_10") ? definitionItemPage : null}
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(DefinitionItem);