import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import 'react-phone-number-input/style.css'
import 'styles/custom/phone-style.css'
import { useSelector } from 'react-redux';
import { closeUpdateModal } from 'store/updateModal/actions';
import { setUnits } from 'store/settings/units/actions';
import { getUnits, updateUnit } from 'service/settings/unitService';
import { clearUpdateUnitFormData, setUpdateUnitFormData } from 'store/settings/updateUnitForm/action';

const UnitUpdateForm = props => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permissions?.permissions || []);
    const formData = useSelector((state) => state.updateUnitFormData?.formData || {});
    const [isHovered, setIsHovered] = useState(false); // Hover durumu için state

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        dispatch(setUpdateUnitFormData({
            ...formData,
            [e.target.name]: inputData,
        }));
    };

    useEffect(() => {
        console.log("formData güncellendi");
        console.log(formData);
    }, [formData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm()
    };

    const handleConfirm = async () => {
        console.log("company add Form Data handle Confirm");
        console.log(formData);
        dispatch(
            openModal({
                title: props.t("modal-update-title"),
                body: props.t("modal-update-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    const { createdAt, updatedAt, depot_no, qr, is_passive,  ...rest } = formData;
                    console.log("update confirm formdata");
                    console.log(formData);

                    console.log("reessstttt");
                    console.log(rest);
                    await updateUnit(rest)
                        .then(async () => {
                            toast.success(props.t("toast-success-update-data"), { position: "top-right" });
                            console.log(formData);
                            const newUnits = await getUnits(); // Fetch updated companies
                            dispatch(setUnits(newUnits)); // Update Redux state
                            dispatch(clearUpdateUnitFormData());
                            dispatch(closeUpdateModal());
                        })
                        .catch((error) => {
                            console.error("Şirket ekelme işlemi başarısız oldu:", error);
                            toast.error(props.t("toast-error-update-data"), { position: "top-right" });
                        });
                }
            })
        );
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update Unit")}</h5>
                    <Button className='text-white' color="warning" type="submit">
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="name">{props.t("Unit Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter unit name")}
                                maxLength={64}
                                minLength={3}
                                required
                            />
                        </FormGroup>
                    </Col>

                </Row>
            </Form>
        </div>
    );
};

UnitUpdateForm.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(UnitUpdateForm)