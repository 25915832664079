import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "store/actions";
import { useSelector } from 'react-redux';
import { hasViewPermission } from 'service/common/permissionService';
import TrashedInslugs from './trashedInslugs';

const TrashedInslug = (props) => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };

  const { t } = useTranslation();
  document.title = t("Trashed Inslugs");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  const trashedInslugsPage =
    <Card className='shadow p-4 rounded-lg'>
      <TrashedInslugs refresh={refresh} />
    </Card>;

  return (
    <div>
      {hasViewPermission(permissions, "DE_01") ? trashedInslugsPage : null}
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(TrashedInslug);