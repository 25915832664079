import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'store/modal/actions'; // Modal actions
import DynamicInput from 'components/Common/Form/DynamicInput';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import FormUpload from 'pages/Forms/FormUpload';
import { createLanguage, getLanguages } from 'service/languageService';
import { useSelector } from 'react-redux';
import { setLanguages } from 'store/languages/action';

const CreateNewLanguage = props => {
    const dispatch = useDispatch();
    const selectedImage = useSelector((state) => state.selectedImage?.selectedImage || []);
    const [newLanguageFormData, setNewLanguageFormData] = useState({});

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value;
        setNewLanguageFormData({
            ...newLanguageFormData,
            [e.target.name]: inputData,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = async () => {
        if(selectedImage.length === 0){
            toast.error(props.t("Please select a flag image"));
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    const flag = await convertFileToBase64(selectedImage[0]);
                    
                    const finalData = {...newLanguageFormData, flag}
                    await createLanguage(finalData)
                        .then(async () => {
                            toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                            const updatedProducts = await getLanguages();
                            dispatch(setLanguages(updatedProducts));

                        })
                        .catch((error) => {
                            console.error("Dil eklenemedi:", error);
                            toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                        });
                    dispatch(closeModal()); // Modalı kapatır
                }
            })
        );
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Add Language")}</h5>
                    <Button className='text-white' color="success" type='submit'>
                        {props.t("Save")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="name">{props.t("Language")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={newLanguageFormData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter language")}
                                required
                                minLength={3}
                                maxLength={32}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="isocode">{props.t("ISO Code")}</Label>
                            <DynamicInput
                                type="text"
                                name="isocode"
                                id="isocode"
                                value={newLanguageFormData.isocode}
                                onChange={handleChange}
                                placeholder={props.t("Enter ISO code")}
                                required
                                minLength={3}
                                maxLength={3}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormUpload />
            </Form>
        </div>
    );
};

CreateNewLanguage.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(CreateNewLanguage)