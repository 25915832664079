import { API_BASE_URL, API_SETTINGS } from 'config';
import { getWithToken, postWithToken } from './common/tokenService';
const USER = '/users';
const CREATE = '/create';
const GET = '/get';
const UPDATE = '/update';
const DELETE = '/passive';

/**
 * Yeni şirket oluşturma fonksiyonu
 * @param {Object} productData - Şirket oluşturma formunda girilen veriler
 * @returns {Promise} - API cevabı
 */
export const createUser = async (productData) => {
  console.log("servis iç");
  console.log(productData);
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${USER}${CREATE}`, productData);
    console.log("createUser");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Şirket eklenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirketleri listeleme fonksiyonu
 * @returns {Promise} - API cevabı
 */
export const getUsers = async () => {
    try {
      const response = await getWithToken(`${API_BASE_URL}${API_SETTINGS}${USER}${GET}`);
      console.log("getUsers");
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Şirketler listelenirken bir hata oluştu:', error);
      throw error;
    }
};

/**
 * Şirket güncelleme fonksiyonu
 * @param {Object} data - Güncellenen şirket bilgileri
 * @returns {Promise} - API cevabı
 */
export const updateUser = async (data) => {
  try {
    console.log ("updateUser");
    console.log(data);
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${USER}${UPDATE}`, data);
    return response.data;
  } catch (error) {
    console.error('Şirket güncellenirken bir hata oluştu:', error);
    throw error;
  }
};

/**
 * Şirket silme fonksiyonu
 * @param {string} id - Silinmek istenen şirketin ID'si
 * @returns {Promise} - API cevabı
 */
export const deleteUser = async (id) => {
  console.log ("deleteUser");
  console.log(id);
  try {
    const response = await postWithToken(`${API_BASE_URL}${API_SETTINGS}${USER}${DELETE}`, {id});
    return response.data;
  } catch (error) {
    console.error('Şirket silinirken bir hata oluştu:', error);
    throw error;
  }
};