import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import DynamicInput from '../../../components/Common/Form/DynamicInput';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { closeModal, openModal } from 'store/modal/actions';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { getDefinitions, updateDefinitionItemData } from 'service/Definitions/definitionsService';
import { setDefinitionItems } from 'store/Definitions/definitionItems/action';
import { closeUpdateModal } from 'store/updateModal/actions';

const DefinitionItemUpdate = props => {
    const dispatch = useDispatch();
    const [updateFormData, setUpdateFormData] = useState({});

    useEffect(() => {
        setUpdateFormData({ id: props.row.id, name: props.row.name });
    }, [dispatch])

    const handleChange = (e) => {
        const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
        setUpdateFormData({ ...updateFormData, [e.target.name]: inputData });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleConfirm();
    };

    const handleConfirm = () => {
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    dispatch(closeModal()); // Close the modal after successful update
                    try {
                        await updateDefinitionItemData(updateFormData)
                            .then(async () => {
                                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                                const newData = await getDefinitions(props.row.up_module_id || -1);
                                dispatch(setDefinitionItems(newData));
                            })
                        dispatch(closeUpdateModal());
                    } catch (error) {
                        console.error("Ürün grubu eklenemedi:", error);
                        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                    }
                }
            })
        );
    }


    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className='d-flex justify-content-between mb-4'>
                    <h5 className="text-primary">{props.t("Update")} {props.name}</h5>
                    <Button color="warning" type='submit'>
                        {props.t("Update")}
                    </Button>
                </div>
                <Row className='mb-4' form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="name">{props.t("Name")}</Label>
                            <DynamicInput
                                type="text"
                                name="name"
                                id="name"
                                value={updateFormData.name}
                                onChange={handleChange}
                                placeholder={props.t("Enter name")}
                                required
                                minLength={3}
                                maxLength={32}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

            <AcceptModal />
        </div>
    );
};

DefinitionItemUpdate.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(DefinitionItemUpdate);
