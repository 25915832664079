import React, { useState, useEffect } from 'react';
import { Badge, Button, Input } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { setCountries } from '../../../store/Definitions/country/action'; // Redux actions
import { openModal, closeModal } from '../../../store/modal/actions'; // Modal actions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from '../../../components/Common/Modals/acceptModal'; // AcceptModal import
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import { setCountryFormData } from 'store/updates/countryForm/action';
import { openUpdateModal } from 'store/updateModal/actions';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission, hasUpdatePermission } from 'service/common/permissionService';
import * as XLSX from 'xlsx'; // Excel export için
import excel from "../../../assets/icons/excel-white.png";
import { deleteUser, getUsers } from 'service/userService';
import { setUserListFormData } from 'store/userList/action';
import UpdateUserForm from './updateUserForm';
import { setUserFormData } from 'store/updates/userForm/action';
import { standartDateTime } from 'components/Common/dateConverter';

const UserList = props => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions?.permissions || []);
  //const [formData, setFormData] = useState({}); // State for form data
  const userList = useSelector((state) => state.userList?.data);
  const [searchText, setSearchText] = useState(''); // State for search text
  const [filteredUsers, setFilteredUsers] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userList = await getUsers(); // Fetch countries from API
        dispatch(setUserListFormData(userList.users));  // Dispatch to Redux store
        setFilteredUsers(userList.users);
      } catch (error) {
        console.error('Error fetching countries:', error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch, props]);

  useEffect(() => {
    console.log("filter user");
    console.log(userList);
    const filteredData = userList?.filter((user) =>
      user.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.surname?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.phone?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.email?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.role_name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
      user.createdAt?.includes(searchText)
    );
    setFilteredUsers(filteredData);
  }, [searchText, userList]);


  useEffect(() => {
    console.log("countryList - formData");
    console.log(userList);
  }, [userList]);

  const handleEdit = (id) => {
    const selectedData = filteredUsers.find((data) => data.id === id);
    dispatch(setUserFormData(selectedData));
    dispatch(
      openUpdateModal({
        body: (
          <UpdateUserForm />
        ),
        onConfirm: async () => {
        },
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(
      openModal({
        title: props.t("modal-delete-title"),
        body: props.t("modal-delete-body"),
        onConfirm: async () => {
          try {
            await deleteUser(id); // Delete country
            toast.success(props.t("toast-success-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after successful update
            const usersData = await getUsers(); // Fetch updated countries
            dispatch(setCountries(usersData.users)); // Update Redux state
            setFilteredUsers(usersData.users); // Update filtered countries
          } catch (error) {
            console.error('Error deleting country:', error);
            toast.error(props.t("toast-error-delete-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        },
      })
    );
  };

  const exportToExcel = () => {

    const column1Name = props.t("id");
    const column2Name = props.t("Name");
    const column3Name = props.t("Phone");
    const column4Name = props.t("Email");
    const column5Name = props.t("Role");
    const column6Name = props.t("Status");
    const column7Name = props.t("Created At");
    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredUsers.map(user => ({
      [column1Name]: user.id,
      [column2Name]: user.name + " " + user.surname,
      [column3Name]: user.phone,
      [column4Name]: user.email,
      [column5Name]: user.role_name,
      [column6Name]: user.is_passive === true ? props.t("Passive") : props.t("Active"),
      [column7Name]: standartDateTime(user.createdAt),

    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Users"));
    XLSX.writeFile(workbook, props.t("Users") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const editIcon = (row) =>
    <Button color="link" onClick={() => handleEdit(row.id)}>
      <FaEdit color="green" size={18} />
    </Button>;

  const deleteIcon = (row) =>
    <Button color="link" onClick={() => handleDelete(row.id)}>
      <FaTrash color="red" size={18} />
    </Button>;

  const restoreIcon = (row) =>
    <Button size='sm' color="success" onClick={() => handleDelete(row.id)}>
      {props.t("Activate")}
    </Button>;

  const columns = [
    {
      name: props.t("#"),
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
      width: "60px"
    },
    {
      name: props.t("Name"),
      selector: (row) => row.name + " " + row.surname,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Phone"),
      selector: (row) => row.phone,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Email"),
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Role"),
      selector: (row) => row.role_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Status"),
      selector: (row) => row.is_passive, // Sıralama için ham veriyi kullanır
      sortable: true, // Sıralamayı aktif hale getirir  
      cell: (row) =>
        row.is_passive === true ?
          <Badge>{props.t("Passive")}</Badge> :
          <Badge color='success'>{props.t("Active")}</Badge>,
      sortFunction: (rowA, rowB) => {
        // "is_passive" değerine göre sıralama yapar: true (Pasif) veya false (Aktif)
        return rowA.is_passive === rowB.is_passive ? 0 : rowA.is_passive ? 1 : -1;
      },
      wrap: true,
    },
    {
      name: props.t("Created At"),
      selector: (row) => standartDateTime(row.createdAt),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row) => (
        row.is_passive === false ?
          <>
            {hasUpdatePermission(permissions, "MM_01") ? editIcon(row) : null}
            {hasDeletePermission(permissions, "MM_01") ? deleteIcon(row) : null}
          </>
          : restoreIcon(row)
      ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h4 className="text-primary">{props.t("User List")}</h4>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredUsers}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "MM_01") ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} // Search input update
              style={{ width: '250px' }} // Smaller input field
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

UserList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(UserList);
