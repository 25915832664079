import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';

import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProfileItem from './profileItem';

const ProfilePage = (props) => {
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [refresh, setRefresh] = useState(false); // Şirket listesi yenileme durumu

  const handleRefresh = () => {
    setRefresh(!refresh); // Form başarıyla tamamlandığında listeyi yeniden yüklemek için state değiştirme
  };
  const { t } = useTranslation();
  document.title = t("Products");
  const breadcrumbItems = [];
  useEffect(() => {
    props.setBreadcrumbItems('', breadcrumbItems)
  },)

  return (
    <div>
      <Card className='shadow p-4 rounded-lg'>
        <ProfileItem />
      </Card>
    </div>
  );
};

export default connect(null, { setBreadcrumbItems })(ProfilePage);