import React, { useState, useEffect } from 'react';
import { Input, Button, Form, FormGroup, Label, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createProduct, getProducts } from '../../service/productService'; // Product ve Kategori servisi
import { createClientProduct, getClientProducts, updateClientProduct } from '../../service/clientProductService'; // Product ve Kategori servisi

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from '../../store/modal/actions'; // Modal actions
import DynamicSelect from 'components/Common/Form/DynamicSelect';
import DynamicInput from 'components/Common/Form/DynamicInput';
import AcceptModal from 'components/Common/Modals/acceptModal';
import { createCompany, getCompanies } from 'service/companyService';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import { setClientProducts } from 'store/clientProduct/action';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { createCountry, getCountries } from 'service/Definitions/countryService';
import { clearClientProductFormData, setClientProductFormData } from 'store/updates/clientProductForm/action';
import { useSelector } from 'react-redux';
import { clearSelectedCompany, setSelectedCompany } from 'store/selectedCompany/action';
import { clearSelectedProduct, setSelectedProduct } from 'store/selectedProduct/action';
import { clearSelectedCountry, setSelectedCountry } from 'store/selectedCountry/action';
import { closeUpdateModal } from 'store/updateModal/actions';
import NoAuthText from 'components/noAuth/noAuthText';
import { hasCreatePermission, hasViewPermission } from 'service/common/permissionService';
import NoAuthUpdateInput from 'components/noAuth/noAuthUpdateInput';

const UpdateClientProductForm = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const formData = useSelector((state) => state.clientProductFormData?.formData || {});
  console.log("updateClientProductFormDataaaaaaaaaaa");
  console.log(formData);
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  //const [selectedCompany, setSelectedCompany] = useState(null);
  const selectedCompany = useSelector((state) => state.selectedCompany?.selectedCompany);
  const selectedProduct = useSelector((state) => state.selectedProduct?.selectedProduct);
  const selectedCountry = useSelector((state) => state.selectedCountry?.selectedCountry);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryList = await getCountries();
      const formattedCountries = countryList.map(country => ({
        value: country.id,
        label: country.name
      }));
      console.log("**********");
      console.log(formattedCountries);
      setCountries(formattedCountries);
      } catch (error) {
        console.log("hata");
      }
    }

    fetchData();

  }, [dispatch]);

  // Kategori verilerini çekmek için useEffect
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const productList = await getProducts(); // Kategorileri çekiyoruz
        setProducts(productList); // Kategorileri state'e set ediyoruz
        const companyList = await getCompanies(); // Kategorileri çekiyoruz
        setCompanies(companyList); // Kategorileri state'e set ediyoruz
      } catch (error) {
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const inputData  = e.target.value === "" || e.target.value === '' ? null : e.target.value.toLocaleUpperCase("tr");
    dispatch(setClientProductFormData({
      ...formData,
      [e.target.name]: inputData,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  const handleSelectProductChange = (field, selected) => {
    dispatch(setSelectedProduct(selected));
    if (selected) {
      dispatch(setClientProductFormData({ ...formData, [field]: selected.value }));
    }
  };

  const handleSelectCompanyChange = (field, selected) => {
    console.log("field111");
    console.log(field);
    console.log("selected111");
    console.log(selected);
    dispatch(setSelectedCompany(selected));
    dispatch(setClientProductFormData({ ...formData, [field]: selected?.value }));
  };

  const handleSelectCountryChange = (field, selected) => {
    console.log(selected);
    dispatch(setSelectedCountry(selected));
    dispatch(setClientProductFormData({ ...formData, [field]: selected ? selected.value : null }));
  };

  const handleCreateCompany = (inputValue) => {
    if(!hasCreatePermission(permissions, "DE_01")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span>{props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newCompany = {
            //client_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
            name: inputValue,  // Kullanıcının girdiği değer
          };
          await createCompany(newCompany)
            .then(async () => {
              const newCompanyList = await getCountries();// Yeni şirketleri yeniden getir
              const formattedCompanies = newCompanyList.map(company => ({
                value: company.client_no,
                label: company.name
              }));
              setCompanies(formattedCompanies);
              dispatch(setSelectedCompany({ value: newCompany.client_no, label: newCompany.name }));
              dispatch(setClientProductFormData({ ...formData, ["client_no"]: newCompany.client_no }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  useEffect(() => {
    console.log("Formdata güncellendi");
    console.log(formData);
  }, [formData])

  useEffect(() => {
    console.log("selectedCategory güncellendi");
    console.log(selectedProduct);
  }, [selectedProduct])

  const handleConfirm = async () => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          const {createdAt, updatedAt, is_deleted, country_name, company_name, product_name, ...rest} = formData;

          await updateClientProduct(rest)
            .then(async () => {
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
              const updatedProducts = await getClientProducts();
              dispatch(setClientProducts(updatedProducts));
              dispatch(clearSelectedCompany());
              dispatch(clearSelectedCountry());
              dispatch(clearSelectedProduct());
              dispatch(clearClientProductFormData());
              dispatch(closeUpdateModal());
            })
            .catch((error) => {
              console.error("Müşteri ürünü eklenemedi:", error);
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            });
          dispatch(closeModal()); // Modalı kapatır
        }
      })
    );
  };

  const handleCreateCountry = (inputValue) => {
    if(!hasCreatePermission(permissions, "DE_10")) {
      toast.error(props.t("No authority"), { position: "top-right" });
      return;
    }
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span>{props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için
          const newCountry = {
            name: inputValue,  // Kullanıcının girdiği değer
          };
          await createCountry(newCountry)
            .then(async (id) => {
              const newCountryList = await getCountries();// Yeni şirketleri yeniden getir
              const addedCountry = newCountryList.find(data => data.id === id);
              const formattedCountries = newCountryList.map(country => ({
                value: country.id,
                label: country.name
              }));
              dispatch(setSelectedCountry({ value: addedCountry.id, label: addedCountry.name }));
              dispatch(setClientProductFormData({ ...formData, ["country_id"]: addedCountry.id }));
              setCountries(formattedCountries);
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  const handleCreateProduct = (inputValue) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: <span> {props.t("modal-save-body")}</span>,
        onConfirm: async () => {
          // Yeni bir şirket oluşturmak için:
          const newProduct = {
            product_no: Math.random().toString(36).substr(2, 8).toUpperCase(), // Rastgele 8 karakterli müşteri numarası
            name: inputValue,  // Kullanıcının girdiği değer
          };

          await createProduct(newProduct)
            .then(async () => {
              const newProductList = await getCompanies();// Yeni şirketleri yeniden getir

              // Yeni şirketi listeye ekle
              setProducts(newProductList);
              dispatch(setSelectedProduct({ value: newProduct.product_no, label: newProduct.name }));
              toast.success(props.t("toast-success-save-data"), { position: "top-right" });
            })
            .catch((error) => {
              toast.error(props.t("toast-error-save-data"), { position: "top-right" });
            }); dispatch(closeModal());
        }
      }));// Modalı açıyoruz
  }

  const formatDateForInput = (isoDate) => {
    if(!isoDate) {
      return null;
    }
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex justify-content-between mb-4'>
          <h5 className="text-primary">{props.t("Update Client Product")}</h5>
          <Button color="warning" type='submit'>
            {props.t("Update")}
          </Button>
        </div>
        <Row className='mb-4' form>
          <Col md={4}>
            <FormGroup>
              <Label for="client_no">{props.t("Company Information")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_01")} props={props} />
              {hasViewPermission(permissions, "DE_01") ? */}
              <DynamicCreatableSelect
                options={companies.map((company) => ({
                  value: company.client_no,  // value'da client_no kullanıyoruz
                  label: company.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedCompany}
                onChange={(selected) => handleSelectCompanyChange("client_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCompany(inputValue) }}
                placeholder={props.t("Select company")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
              {/* :
              <NoAuthUpdateInput data={formData.company_name} />
              } */}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="product_no">{props.t("Product Information")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_08")} props={props} />
              {hasViewPermission(permissions, "DE_08") ? */}
              <DynamicSelect
                options={products.map((product) => ({
                  value: product.product_no,  // value'da client_no kullanıyoruz
                  label: product.name,  // label'da company.name kullanıyoruz
                }))}
                value={selectedProduct}
                onChange={(selected) => handleSelectProductChange("product_no", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateProduct(inputValue.toLocaleUpperCase("tr")) }}
                placeholder={props.t("Select product")}
                isClearable
                required
                noOptionsMessage={() => props.t("no-option-message")}
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue.toLocaleUpperCase("tr")}`} // Yeni seçenek ekleme mesajı
                filterOption={(option, inputValue) => {
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const clientNoMatch = option.value.toLowerCase().includes(inputValue.toLowerCase());
                  const companyNameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());

                  return clientNoMatch || companyNameMatch;
                }}
              />
              {/* :
              <NoAuthUpdateInput data={formData.product_name} />
              } */}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="extern_no">{props.t("Extern No")}</Label>
              <DynamicInput
                type="text"
                name="extern_no"
                id="extern_no"
                value={formData.extern_no}
                onChange={handleChange}
                placeholder={props.t("Enter extern no")}
              />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="origin_country">{props.t("Origin Country")}</Label>
              {/* <NoAuthText hasPermission={hasViewPermission(permissions, "DE_11")} props={props} />
              {hasViewPermission(permissions, "DE_11") ? */}
              <DynamicCreatableSelect
                options={countries}
                value={selectedCountry}
                onChange={(selected) => handleSelectCountryChange("country_id", selected)} // Kategori seçimi
                onCreateOption={async (inputValue) => { handleCreateCountry(inputValue) }}
                placeholder={props.t("Enter country")}
                isClearable
                noOptionsMessage={() => props.t("no-option-message")}
                formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`} // Yeni seçenek ekleme mesajı
                onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                filterOption={(option, inputValue) => {
                  const clientNo = option.data.label || "";
                  // Hem client_no hem de company.name üzerinden arama yapabilmek için
                  const companyNameMatch = clientNo.toLowerCase().includes(inputValue.toLowerCase());

                  return companyNameMatch;
                }}
              />
              {/* :
              <NoAuthUpdateInput data={formData.country_name} />
              } */}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <Label for="production_date">{props.t("Production Date")}</Label>
              <DynamicInput
                type="date"
                id="production_date"
                name="production_date"
                value={formatDateForInput(formData.production_date)}
                onChange={handleChange}
                placeholderText={props.t("Enter production date")}
                minLength={3}
                maxLength={32}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

UpdateClientProductForm.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(UpdateClientProductForm)