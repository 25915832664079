import React from 'react';
import Select from 'react-select';

// Dinamik React Select fonksiyonu
const DynamicSelect = ({
  name = '',
  options = null,
  value = null,
  onChange = () => {},
  placeholder = 'Lütfen bir seçenek seçin',
  styles = {
    control: (baseStyle, state) => ({
      ...baseStyle,
      backgroundColor: 'transparent',
      border: '2px solid #E1AFD1',  // Sabit mavi border
      boxShadow: 'none',  // Focus durumunda gölge eklenmeyecek
      '&:hover': { borderColor: '#7469B6' },  // Hover durumunda da mavi kalacak
      padding: '8px',
      borderRadius: '8px',
    }
    ),
  },
  className = 'shadow bg-white',
  isClearable = true,
  filterOption=null,
  required = false,
  onInputChange = null,
  noOptionsMessage = () => "No record found",

}) => {
  return (
    <Select
      name={name}
      className={className}
      options={options}
      value={value}
      styles={styles}
      onChange={onChange}
      placeholder={placeholder}
      style={{ border: '2px solid #E1AFD1', borderRadius: '8px' }} // Border ve renk burada tanımlandı
      isClearable={isClearable}
      filterOption={filterOption}
      required={required}
      onInputChange={onInputChange}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default DynamicSelect;
