/* import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import axios from "axios";

// i18n
import i18n from "../../../i18n";
import { getLanguages } from "service/languageService";

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getLanguages();
        const langs = response.map((lang) => ({
          code: lang.isocode.toLowerCase(),
          label: lang.name,
          flag: lang.flag,
        }));
        setLanguages(langs);

        // Varsayılan dili belirle
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE") || langs[0].code;
        setSelectedLang(currentLanguage);
        i18n.changeLanguage(currentLanguage);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguageAction = (lang) => {
    // Dili i18n olarak değiştir
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-none d-lg-inline-block ms-2">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={languages.find((l) => l.code === selectedLang)?.flag}
            alt=""
            height="16"
            className="me-2"
          />
          {" "}
          {languages.find((l) => l.code === selectedLang)?.label}{" "}
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {languages.map((lang) => (
            <DropdownItem
              key={lang.code}
              onClick={() => changeLanguageAction(lang.code)}
              className={`notify-item ${
                selectedLang === lang.code ? "active" : "none"
              }`}
            >
              <img
                src={lang.flag}
                alt="Header Language"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {lang.label}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown); */




/* import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"

//i18n
import i18n from "../../../i18n"
import languages from "common/languages"

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLang(currentLanguage)
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-none d-lg-inline-block ms-2">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Header Language"
            height="16"
            className="me-2"
          />
         {" "}
            {get(languages, `${selectedLang}.label`)}{" "}
            <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Header Language"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown) */


import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { getLanguages } from "service/languageService";
import usaFlag from '../../../assets/images/flags/abd.png';

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getLanguages();
        const tempResponse = response.filter(data => data.is_passive === false);
        const langs = tempResponse.map((lang) => ({
          code: lang.isocode,
          label: lang.name,
          flag: lang.flag,
        }));
        setLanguages(langs);
        console.log("selectedLocalLang");
        const selectedLocalLang = localStorage.getItem("I18N_LANGUAGE");
        console.log("langs");
        console.log(langs);
        // Set the default language
        const currentLanguage = selectedLocalLang || langs[0].code;
        console.log(currentLanguage);
        setSelectedLang(currentLanguage);
        await i18n.changeLanguage(currentLanguage);
      } catch (error) {
        console.error("Error fetching languages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguageAction = async (lang) => {
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("I18N_LANGUAGE", lang);
      setSelectedLang(lang);
    } catch (error) {
      console.error("Language switch error:", error);
    }
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-lg-inline-block ms-2">
        { (loading || languages.length < 1) ? 
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={usaFlag}
            height="16"
            className="me-2"
          />
          English
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle> 
        : 
        selectedLang === null || selectedLang === "en" || selectedLang === "bel" ?
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={usaFlag}
            height="16"
            className="me-2"
          />
          English
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle> 
        :
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={languages.find((l) => l.code === selectedLang)?.flag}
            height="16"
            className="me-2"
          />
          {languages.find((l) => l.code === selectedLang)?.label}
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>}
        {languages.length > 0 ? <DropdownMenu className="language-switch dropdown-menu-end">
          {loading ? <DropdownItem
            key="ENG"
            onClick={() => { }}
          >
            <img
              src={usaFlag}
              className="me-1"
              height="12"
            />
            <span className="align-middle">English</span>

          </DropdownItem>

            : languages.map((lang) => (
              <DropdownItem
                key={lang.code}
                onClick={() => changeLanguageAction(lang.code)}
                className={`notify-item ${selectedLang === lang.code ? "active" : ""
                  }`}
              >
                <img
                  src={lang.flag}
                  className="me-1"
                  height="12"
                />
                <span className="align-middle">{lang.label}</span>
              </DropdownItem>
            ))}

        </DropdownMenu>: null}
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);

/* import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { getLanguages } from "service/languageService";

const LanguageSelect = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getLanguages();
        const langs = response.map((lang) => ({
          code: lang.isocode,
          label: lang.name,
          flag: lang.flag,
        }));
        setLanguages(langs);

        // Set the default language
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE") || langs[0].code;
        setSelectedLang(currentLanguage);
        await i18n.changeLanguage(currentLanguage);
      } catch (error) {
        console.error("Error fetching languages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguageAction = async (event) => {
    const lang = event.target.value;
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("I18N_LANGUAGE", lang);
      setSelectedLang(lang);
    } catch (error) {
      console.error("Language switch error:", error);
    }
  };

  return (
    <>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <select
          value={selectedLang}
          onChange={changeLanguageAction}
          className="form-select language-select"
        >
          {languages.map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.label}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default withTranslation()(LanguageSelect); */

/* import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { getLanguages } from "service/languageService";
import Select from "react-select"; // Use react-select for custom rendering
import '../../../styles/languageSelectStyle.css'

const LanguageSelect = () => {
  const [selectedLang, setSelectedLang] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await getLanguages();
        const langs = response.map((lang) => ({
          value: lang.isocode,
          label: lang.name,
          flag: lang.flag,
        }));
        setLanguages(langs);

        // Set the default language
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE") || langs[0].value;
        setSelectedLang(langs.find((lang) => lang.value === currentLanguage));
        await i18n.changeLanguage(currentLanguage);
      } catch (error) {
        console.error("Error fetching languages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  const changeLanguageAction = async (selectedOption) => {
    const lang = selectedOption.value;
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("I18N_LANGUAGE", lang);
      setSelectedLang(selectedOption);
    } catch (error) {
      console.error("Language switch error:", error);
    }
  };

  const customOption = ({ label, flag }) => (
    <div className="custom-option">
      <img src={flag} alt={label} style={{ width: 20, height: 15, marginRight: 8 }} />
      {label}
    </div>
  );

  return (
    <>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <Select
          value={selectedLang}
          onChange={changeLanguageAction}
          options={languages}
          getOptionLabel={(option) => (
            <div className="language-option">
              <img
                src={option.flag}
                alt={option.label}
                style={{ width: 20, height: 15, marginRight: 8 }}
              />
              {option.label}
            </div>
          )}
          getOptionValue={(option) => option.value}
          className="language-select"
        />
        </div>
      )}
    </>
  );
};

export default withTranslation()(LanguageSelect); */

