/* import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import { getLanguages } from "service/languageService";

// Asenkron olarak dilleri yükleyen fonksiyon
const loadLanguages = async () => {
  try {
    // API'den veriyi çek
    const languages = await getLanguages();

    console.log("Languages from API:", languages);

    // `resources` nesnesini oluştur
    const resources = languages.reduce((acc, lang) => {
      acc[lang.isocode.toLowerCase()] = {
        translation: lang.content,
      };
      return acc;
    }, {});

    // i18n yapılandırmasını başlat
    i18n
      .use(detector)
      .use(initReactI18next)
      .init({
        resources, // Dinamik olarak alınan çevirileri burada kullanın
        lng: localStorage.getItem("I18N_LANGUAGE") || "en",
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
          escapeValue: false,
        },
      });

  } catch (error) {
    console.error("Dilleri yüklerken hata oluştu:", error);
  }
};

// Dil yapılandırmasını yükle
loadLanguages();

export default i18n; */
/* 
import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { getLanguages } from "service/languageService"

await getLanguages().then((languages)=>{
  console.log("Languages from API:", languages);

  // `resources` nesnesini oluştur
  const resources = languages.reduce((acc, lang) => {
    acc[lang.isocode.toLowerCase()] = {
      translation: lang.content,
    };
    return acc; 
  }, {});
  
  const language = localStorage.getItem("I18N_LANGUAGE")
  if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "eng")
  }
  i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "eng",
    fallbackLng: "eng", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
});

export default i18n */




/* import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationGr from "./locales/gr/translation.json"
import translationIT from "./locales/it/translation.json"
import translationRS from "./locales/rs/translation.json"
import translationSP from "./locales/sp/translation.json"
import translationTR from "./locales/tr/translation.json"
import translationBEL from "./locales/bel/translation.json"
import translationENG from "./locales/eng/translation.json"

// the translations
const resources = {
  gr: {
    translation: translationGr,
  },
  it: {
    translation: translationIT,
  },
  rs: {
    translation: translationRS,
  },
  sp: {
    translation: translationSP,
  },
  tr: {
    translation: translationTR,
  },
  bel: {
    translation: translationBEL,
  },
  en: {
    translation: translationENG,
  },
}

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n */



import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import { getLanguages } from "service/languageService";

// Function to fetch all available languages and their content
const fetchAllLanguages = async () => {
  try {
    const languages = await getLanguages();
    const resources = {};

    // Loop through each language object and add it to the resources
    for (const language of languages) {
      const { isocode, content } = language;
      resources[isocode] = { translation: content };
    }

    return resources;
  } catch (error) {
    console.error("Failed to load languages:", error);
    return {}; // Return an empty object if there's an error
  }
};

// Initialize i18n with the resources fetched from the API
const initializeI18n = async () => {
  const language = localStorage.getItem("I18N_LANGUAGE") || "ENG";

  if (!localStorage.getItem("I18N_LANGUAGE")) {
    localStorage.setItem("I18N_LANGUAGE", "ENG");
  }

  await fetchAllLanguages().then(async (resources) => {
    console.log("resourcessss");
    console.log(resources);
    await i18n
      .use(detector)
      .use(initReactI18next)
      .init({
        resources,
        lng: language,
        fallbackLng: "ENG",
        keySeparator: false,
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
  });
  //i18n.changeLanguage(language)
};

// Call the initialization function
await initializeI18n();

export default i18n;

