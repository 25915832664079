import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { createInslug } from "../../../../service/inslugService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetInslugForm } from '../../../../store/depot/inslug/actions';
import { resetInslugItems, setInslugItemData } from '../../../../store/depot/inslugItem/actions';
import { resetSelectedProducts, setSelectedProducts } from "store/depot/selectedProducts/actions";
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import AcceptModal from "components/Common/Modals/acceptModal";
import { closeModal, openModal } from "store/modal/actions";

const InslugActions = props => {
    const dispatch = useDispatch();

    // Redux state
    const newInslug = useSelector((state) => state.inslug);
    const inslugItems = useSelector((state) => state.inslugItems?.inslugItems || []);



    useEffect(() => {
        console.log("redux newInslug güncellendi");
        console.log(newInslug);
    }, [newInslug]);

    useEffect(() => {
        console.log("redux inslugItems güncellendi");
        console.log(inslugItems);
    }, [inslugItems]);

    const validation = () => {
        if (!newInslug.client_no) {
            toast.error(props.t("error-company-empty"), { position: "top-right" });
            return false; 
        }
        if (!newInslug.document_no) {
            toast.error(props.t("error-document-empty"), { position: "top-right" });
            return false; 
        }
        return true;
    }

    const sendData = async () => {
        const validationResult = validation();
        if(validationResult === false) {
            return;
        }
        dispatch(
            openModal({
                title: props.t("modal-save-title"),
                body: props.t("modal-save-body"),
                onConfirm: async () => {
                    try {
                        // Eğer client_no null değilse, işlem yapılır
                        const inslugData = createInslugJson(newInslug);
                        const inslugItemData = extractSelectedFields(inslugItems);
                        const data = { inslug: inslugData, inslugItem: inslugItemData };
                        console.log(data);
                        const response = await createInslug(data)
                            .then(() => {
                                toast.success(props.t("toast-success-save-data"), { position: "top-right" });
                                dispatch(resetInslugForm()); // Formu sıfırla
                                dispatch(resetInslugItems());
                                dispatch(resetSelectedProducts([]));
                            })
                        dispatch(closeModal()); // Close the modal after successful update

                    } catch (error) {
                        console.error("Hata:", error);
                        toast.error(props.t("toast-error-save-data"), { position: "top-right" });
                    }
                }
            })
        );
    };

    const createInslugJson = (data) => {
        const inslugData = {
            client_no: data.client_no,
            transport_company_no: data.transport_company_no,
            booking_date: data.booking_date,
            document_no: data.document_no,
            extern_document_no: data.extern_document_no,
            agency_reference: data.agency_reference,
            description: data.description,
            eta: data.eta,
            closing_out: data.closing_out,
            closing_in: data.closing_in,
            customs_status_id: data.customs_status_id,
            supplier_company_no: data.supplier_company_no,
            carrier_type_id: data.carrier_type_id,
            loading_status_id: data.loading_status_id,
            process_status_id: data.process_status_id,
        };
        return inslugData;
    };

    function extractSelectedFields(data) {
        console.log("extract json");
        console.log(data);
        // Yeni JSON arrayini oluştur
        const result = data.map(item => {
            return {
                client_product_id: item.client_product_id || null,
                container_no: item.container_no || null,
                leverage_charge: item.leverage_charge || null,
                description: item.description || null,
                count: parseInt(item.count, 10) || null
            };
        });

        return result;
    }
    return (
        <div>
            <div className="text-center">
                <Button className="btn btn-md px-3" onClick={sendData} color="success">
                    {props.t("Save")}
                </Button>
            </div>

            <AcceptModal />
        </div>
    );
};

InslugActions.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(InslugActions)