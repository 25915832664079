import axios from "axios";

export const getWithToken = async (url) => {
    try {
        const token = localStorage.getItem('token');
    const response = await axios.get(url,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return response;
    } catch (error) {
        if(error.status === 402) {
            window.location.href = '/logout';  // Bu yöntem sayfayı yeniden yükler
        } else {
            throw error;
        }
    }
}

export const postWithToken = async (url, data) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        url, 
        data,
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
        );
      return response;
    } catch (error) {
      if(error.status === 402) {
        window.location.href = '/logout';  // Bu yöntem sayfayı yeniden yükler
      } else {
        console.error('Ülke eklenirken bir hata oluştu:', error);
        throw error;
      }
    }
  };