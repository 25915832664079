import React, { useEffect, useState } from 'react';
import { Button, Table, Input, FormGroup, Card } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { addInslugItemRow, setInslugItemData, removeLastInslugItemRow, removeInslugItemRow } from '../../../../store/depot/inslugItem/actions';
import DynamicCreatableSelect from 'components/Common/Form/DynamicCreatableSelect';
import CreatableSelect from 'react-select/creatable';
import DynamicInput from 'components/Common/Form/DynamicInput';
import { inslugItemInfo } from 'service/inslugItemService';
import { setSelectedProducts } from 'store/depot/selectedProducts/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { setSelectedClientProducts } from 'store/depot/selectedClientProducts/actions';
import NoAuthText from 'components/noAuth/noAuthText';
import { hasViewPermission } from 'service/common/permissionService';
import NoAuthInput from 'components/noAuth/noAuthCreateInput';
import { closeModal, openModal } from 'store/modal/actions';

const AddInslugItem = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const newInslug = useSelector((state) => state.inslug);
  const inslugItems = useSelector((state) => state.inslugItems?.inslugItems || []);
  const selectedProducts = useSelector((state) => state.selectedProducts?.selectedProducts || []);
  //const [clientProducts, setClientProducts] = useState([]);

  const clientProducts = useSelector((state) => state.selectedClientProducts?.selectedClientProducts || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientProductData = await inslugItemInfo()
        console.log("clientProductInfo");
        console.log(clientProductData);
        const formattedClientProducts = clientProductData.map(product => ({
          clientNo: product.client_no,
          value: product.client_product_id,
          productNo: product.product_no,
          productName: product.product_name,
          label: customLabel(product)
        }));

        dispatch(setSelectedClientProducts(formattedClientProducts));
      } catch (error) {
        console.log(error);
        //toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }

    }
    fetchData()
  }, [dispatch]);

  const handleSelectChange = (index, field, selectedOption) => {
    console.log("handleSelectChange");
    console.log(selectedOption);
    let updatedSelectedProducts = [...selectedProducts];
    updatedSelectedProducts[index] = selectedOption;  // Seçilen ürünü ilgili index'te güncelle
    dispatch(setSelectedProducts(updatedSelectedProducts));

    dispatch(setInslugItemData(index, field, selectedOption ? selectedOption.value : null));
    //dispatch(setInslugItemData(index, "client_product_id", selectedOption ? selectedOption.client_product_id : null));

  };

  const handleCreateOption = (inputValue, index) => {
    dispatch(
      openModal({
        title: props.t("modal-save-title"),
        body: props.t("modal-save-body"),
        onConfirm: async () => {
          //handleCreateOption(inputValue);
          dispatch(closeModal());
        }
      }));
  };

  const handleInputChange = (index, e) => {
    dispatch(setInslugItemData(index, e.target.name, e.target.value.toLocaleUpperCase("tr")));
  };

  // Handle input changes and dispatch actions to update Redux state
  const handleCountChange = (index, e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    const name = e.target.name;
    dispatch(setInslugItemData(index, name, value));
  };

  const addNewRow = () => {
    console.log("selecteeeeeeedProdduuuuccttsss");
    console.log(selectedProducts);
    dispatch(addInslugItemRow());
    dispatch(setSelectedProducts([...selectedProducts, null]));  // Yeni satır için başlangıçta null seçili
  };

  /*   const removeLastRow = () => {
      if (inslugItems.length > 0) {
        dispatch(removeLastInslugItemRow());  // Redux'tan son satırı sil
        let updatedSelectedProducts = [...selectedProducts];  // Mevcut listeyi kopyalayın
        updatedSelectedProducts = updatedSelectedProducts.slice(0, -1);  // Son öğeyi sil
        dispatch(setSelectedProducts(updatedSelectedProducts));  // Redux state'i güncelleyin
      }
    }; */

  const removeIndexRow = (index) => {
    if (inslugItems.length > 0) {
      dispatch(removeInslugItemRow(index));  // Redux'tan son satırı sil
    }
  };

  const customLabel = (product) => {
    return <div className="d-flex align-items-center">
      {/* <span className='shadow rounded bg-info px-1 text-white mx-2' color="info" pill>{`${product.client_no}`}</span>
      <span className=''>{product.company_name}</span>
      <span className='mx-2'>|</span> */}
      <span className='rounded bg-warning px-1 text-white mx-2' color="info" pill>{`${product.product_no}`}</span>
      <span>{product.product_name}</span>
    </div>;
  }

  return (
    <Card className="p-4 rounded-lg">
      <div className="d-flex mb-3 justify-content-between">
        <h5 className='text-primary'>{props.t("Product Information")}</h5>
        <div>
          <Button className="btn btn-md mx-2" color="success" onClick={addNewRow}>+</Button>
        </div>
      </div>
      <FormGroup>
        <Table>
          <thead>
            <tr>
              <th>
                {props.t("Client Product")}
               {/*  <NoAuthText hasPermission={hasViewPermission(permissions, "DE_09")} props={props} /> */}
              </th>
              <th>{props.t("Container No")}</th>
              <th>{props.t("Leverage Charge")}</th>
              <th>{props.t("Description")}</th>
              <th>{props.t("Product Count")}</th> 
              <th></th>
            </tr>
          </thead>
          <tbody>
            {inslugItems.map((item, index) => (
              <tr key={index}>
                <td>
                  {/* {hasViewPermission(permissions, "DE_09") ? */}
                  <DynamicCreatableSelect
                    name="client_product_id"
                    value={clientProducts.find(option => option.value === item.client_product_id)}
                    options={clientProducts.filter(product => product.clientNo === newInslug.client_no)} // Filtreleme işlemi
                    //noOptionsMessage={() => props.t("no-option-message")}
                    onChange={(selectedOption) => handleSelectChange(index, 'client_product_id', selectedOption)}
                    onCreateOption={(inputValue) => handleCreateOption(inputValue, index)}  // Yeni ürün ekleme
                    placeholder={props.t("Select client product")}
                    formatCreateLabel={(inputValue) => `${props.t("Add")}: ${inputValue}`}
                    onInputChange={(inputValue) => inputValue.toLocaleUpperCase("tr")}
                    noOptionsMessage={() => props.t("no-option-message")}
                    filterOption={(option, inputValue) => {
                      console.log("addInslugItem option");
                      console.log(option);
                      console.log("addInslugItem inputValue");
                      console.log(inputValue);
                      const productNo = option.data.productNo || "";
                      const productName = option.data.productName || "";
                      const productNoMatch = productNo.toLowerCase().includes(inputValue.toLowerCase());
                      const productNameMatch = productName.toLowerCase().includes(inputValue.toLowerCase());

                      return productNoMatch || productNameMatch;
                    }}
                  />
                  {/* :
                  <NoAuthInput placeholder={props.t("Select client product")} />
                  } */}
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="container_no"
                    value={item.container_no}
                    onChange={(e) => handleInputChange(index, e)}
                    placeholder={props.t("Enter container no")}
                    required
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="leverage_charge"
                    value={item.leverage_charge}
                    placeholder={props.t("Enter leverage charge information")}
                    onChange={(e) => handleInputChange(index, e)}
                    minLength={3}
                    maxLength={32}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="text"
                    name="description"
                    value={item.description}
                    placeholder={props.t("Enter description")}
                    onChange={(e) => handleInputChange(index, e)}
                    minLength={3}
                    maxLength={255}
                  />
                </td>
                <td>
                  <DynamicInput
                    type="number"
                    id="count"
                    name="count"
                    value={item.count}
                    placeholder={props.t("Enter product count")}
                    onChange={(e) => handleCountChange(index, e)}
                  />
                </td>
                <td>
                  <div className='d-flex align-items-center justify-content-center my-2'> 
                  <Button className='btn btn-md' color="danger" onClick={() => removeIndexRow(index)}>-</Button> {/* Son satırı sil */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </FormGroup>
    </Card>
  );
};

AddInslugItem.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(AddInslugItem)