import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasExcelPermission, hasViewPermission } from 'service/common/permissionService';
import DetailsButton from 'components/Common/Button/detailsButton';
import { useNavigate } from 'react-router-dom';

const TrashedList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState('');
  const [filteredTrahsedList, setFilteredTrashedList] = useState([]);

  const trashedDatas = [
    hasExcelPermission(permissions, "DE_01") ? { name: props.t("Company"), address: "/depot/trash/company" } : {},
    hasExcelPermission(permissions, "DE_02") ? { name: props.t("Extern Company"), address: "/depot/trash/extern-company" } : {},
    hasExcelPermission(permissions, "DE_08") ? { name: props.t("Product"), address: "/depot/trash/product" } : {},
    hasExcelPermission(permissions, "DE_07") ? { name: props.t("Product Category"), address: "/depot/trash/product-category" } : {},
    hasExcelPermission(permissions, "DE_09") ? { name: props.t("Client Product"), address: "/depot/trash/client-product" } : {},
  ]

  useEffect(() => {
    setFilteredTrashedList(trashedDatas);
  }, [dispatch]);

  useEffect(() => {
    const filteredData = trashedDatas.filter((item) =>
      item.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"))
    );
    setFilteredTrashedList(filteredData);
  }, [searchText]);

  const handleViewDetails = (address) => {
    navigate(`${address}`);
  };

  const columns = [
    {
      name: props.t("Name"),
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row) => (
        <>
          <DetailsButton disabled={false} onClick={() => handleViewDetails(row.address)} />
        </>
      ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h5 className="text-primary">{props.t("Definitions List")}</h5>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredTrahsedList}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")}
        paginationComponentOptions={customPaginationOptions}
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: '250px' }}
            />
          </div>
        }
      />

      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

TrashedList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(TrashedList);
