import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { FaRecycle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptModal from 'components/Common/Modals/acceptModal'; // AcceptModal import
import { deleteRole, getDeletedRoles, getRoles } from 'service/settings/rolesService';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import UpdateModal from 'components/Common/Modals/updateModal';
import { hasDeletePermission, hasExcelPermission } from 'service/common/permissionService';
import * as XLSX from 'xlsx'; // Excel export için
import excel from "assets/icons/excel-white.png";
import { standartDateTime } from 'components/Common/dateConverter';
import { closeModal, openModal } from 'store/modal/actions';
import RestoreButton from 'components/Common/Button/restoreButton';

const TrashedRoles = props => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions?.permissions || []);
  const [searchText, setSearchText] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesList = await getDeletedRoles(); // Fetch countries from API
        console.log("rolesLissttt");
        console.log(rolesList);
        console.log("isArraymiiii");
        setRoles(rolesList);  // Dispatch to Redux store
        setFilteredRoles(rolesList);
      } catch (error) {
        console.error('Error fetching countries:', error);
        toast.error(props.t("toast-error-fetch-data"), { position: "top-right" });
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    console.log("filteredilecek liste");
    console.log(roles);
    const filteredData = roles?.filter((item) =>
      item.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"))
    );
    setFilteredRoles(filteredData);
  }, [searchText, roles]);

  const handleRestore = (id) => {
    console.log(id);
    dispatch(
      openModal({
        title: props.t("modal-restore-title"),
        body: props.t("modal-restore-body"),
        onConfirm: async () => {
          dispatch(closeModal()); // Close the modal after successful update
          try {
            await deleteRole(id); // Delete country
            toast.success(props.t("toast-success-restore-data"), { position: "top-right" });
            const newRoles = await getRoles(); // Fetch updated countries
            const newDeletedRoles = await getDeletedRoles(); // Fetch updated countries
            setRoles(newDeletedRoles); // Update Redux state
            setFilteredRoles(newDeletedRoles); // Update filtered countries
            dispatch(setRoles(newRoles));

          } catch (error) {
            console.error('Error deleting country:', error);
            toast.error(props.t("toast-error-restore-data"), { position: "top-right" });
            dispatch(closeModal()); // Close the modal after failure
          }
        },
      })
    );
  };

  const exportToExcel = () => {

    const column1Name = props.t("Name");
    const column2Name = props.t("Created At");

    // Sadece 1. ve 2. sütunları (veri) seçiyoruz
    const selectedColumns = filteredRoles.map(item => ({
      [column1Name]: item.name, // 2. sütun verisi
      [column2Name]: standartDateTime(item.createdAt), // 2. sütun verisi
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Roles"));
    XLSX.writeFile(workbook, props.t("Roles") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0 " color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const restoreIcon = (row) => <RestoreButton onClick={() => handleRestore(row.id)} />

  const columns = [
    {
      name: props.t("#"),
      selector: (row) => row.role_id,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Name"),
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Actions"),
      cell: (row) => (
        <>
          {hasDeletePermission(permissions, "MM_02") ? restoreIcon(row) : null}
        </>
      ),
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"), // Burada yazıyı özelleştiriyoruz
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h4 className="text-primary">{props.t("Roles")}</h4>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredRoles}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")} // Boş veri mesajını özelleştiriyoruz
        paginationComponentOptions={customPaginationOptions} // Özelleştirilmiş yazı ekledik
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {hasExcelPermission(permissions, "MM_02") ? excelIcon : null}
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value.toLocaleUpperCase("tr"))} // Search input update
              style={{ width: '250px' }} // Smaller input field
            />
          </div>
        }
      />

      {/* Modal rendered by Redux */}
      <AcceptModal />
      <UpdateModal />
    </div>
  );
};

TrashedRoles.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(TrashedRoles);
