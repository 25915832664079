import React, { useState, useEffect } from 'react';
import { Badge, Button, Input } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import customStyles from 'styles/customDatatableStyle';
import * as XLSX from 'xlsx';
import excel from "../../../assets/icons/excel-white.png";
import { getActivitiesList, getLogs } from 'service/logService';
import { standartDateTime } from 'components/Common/dateConverter';

const LogList = (props) => {

  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [statusOptions, setStatusOptions] = useState([]); // Dropdown için status seçenekleri

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logList = await getLogs();
        setLogs(logList);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };
    fetchData();
  }, [props]);

  useEffect(() => {
    // Status seçeneklerini getActivitiesList fonksiyonundan çekme
    const fetchStatusOptions = async () => {
      try {
        const activities = await getActivitiesList();
        const uniqueActivities = [...new Set(activities.map(item => item.activity_no))];
        setStatusOptions(uniqueActivities);
      } catch (error) {
        console.error('Error fetching activity list:', error);
      }
    };
    fetchStatusOptions();
  }, []);

  const convertDate = (dateData) => {
    const date = new Date(dateData);
    return date.toLocaleString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  useEffect(() => {
    const filteredData = logs.filter((log) => {
      const matchesText =
        log.name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
        log.surname?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
        log.module_name?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
        log.activity_no?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr")) ||
        log.createdAt?.toLocaleUpperCase("tr").includes(searchText.toLocaleUpperCase("tr"));

      const matchesStatus =
        statusFilter === '' || log.activity_no === statusFilter;

      return matchesText && matchesStatus;
    });

    setFilteredLogs(filteredData);
  }, [searchText, statusFilter, logs]);

  const exportToExcel = () => {
    const column1Name = props.t("User ID");
    const column2Name = props.t("User");
    const column3Name = props.t("Module Name");
    const column4Name = props.t("Date");
    const column5Name = props.t("Activity No");

    const selectedColumns = filteredLogs.map(log => ({
      [column1Name]: log.user_id,
      [column2Name]: log.name + " " + log.surname,
      [column3Name]: log.module_name,
      [column4Name]: standartDateTime(log.createdAt),
      [column5Name]: log.activity_no,
    }));

    const worksheet = XLSX.utils.json_to_sheet(selectedColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, props.t("Logs"));
    XLSX.writeFile(workbook, props.t("Logs") + ".xls");
  };

  const excelIcon =
    <Button className="me-2 p-0" color="success p-2" onClick={exportToExcel}>
      <img src={excel} width={'24'} alt="" />
    </Button>;

  const columns = [
    {
      name: props.t("User ID"),
      selector: (row) => row.user_id,
      sortable: true,
      wrap: true,
      width: "130px"
    },
    {
      name: props.t("User"),
      selector: (row) => row.name + " " + row.surname,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Module Name"),
      selector: (row) => row.module_name,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Date"),
      selector: (row) => convertDate(row.createdAt),
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Status"),
      selector: (row) => row.activity_no,
      sortable: true,
      cell: (row) =>
        row.activity_no === "VIEW" ? <Badge color='success'>{props.t("VIEW")}</Badge> :
          row.activity_no === "CREATE" ? <Badge color='primary'>{props.t("CREATE")}</Badge> :
            row.activity_no === "DELETE" ? <Badge color='danger'>{props.t("DELETE")}</Badge> :
              row.activity_no === "UPDATE" ? <Badge color='warning'>{props.t("UPDATE")}</Badge> :
                row.activity_no === "EXCEL" ? <Badge color='info'>{props.t("EXCEL")}</Badge> :
                  <Badge>{props.t(row.activity_no)}</Badge>,
      wrap: true,
    }
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <h4 className="text-primary">{props.t("Logs")}</h4>

      <DataTable
        className='p-3'
        columns={columns}
        data={filteredLogs}
        customStyles={customStyles}
        noDataComponent={props.t("table-empty-message")}
        paginationComponentOptions={customPaginationOptions}
        pagination
        paginationPerPage={500}
        highlightOnHover
        subHeader
        subHeaderComponent={
          <div className='d-flex justify-content-end w-50'>
            {excelIcon}
            <Input
              type="select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              style={{ minWidth: "100px", maxWidth: '250px', marginRight:'10px' }}

            >
              <option value="">{props.t("All Statuses")}</option>
              {statusOptions.map((status) => (
                <option key={status} value={status}>
                  {props.t(status)}
                </option>
              ))}
            </Input>
            <Input
              type="text"
              placeholder={props.t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ minWidth: "100px", maxWidth: '250px' }}
            />
          </div>
        }
      />
    </div>
  );
};

LogList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(LogList);
