import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { clearButtonLoading, setButtonLoading } from 'store/buttonLoading/action';
import { Button } from 'reactstrap';

const DynamicButton = ({ text, onClick = async () => { }, type = "submit", buttonType = 0 }) => {
    const dispatch = useDispatch();
    const buttonLoading = useSelector((state) => state.buttonLoading?.loading);

    const handleClick = async (event) => {
        if (type === "submit") return; // Submit butonu olduğunda `handleClick` devre dışı
        if (!buttonLoading) {
            /* dispatch(setButtonLoading()); */
            await onClick();
        }
    };

    const handleButtonColor = (buttonType) => {
        switch (buttonType) {
            case 0:
                return 'success';
            case 1:
                return 'warning';
            case 2:
                return 'danger';
            case 3:
                return 'primary';
            default:
                return 'success';
        }
    };

    return (
        <Button
            color={handleButtonColor(buttonType)}
            type={type}
            onClick={handleClick}
            disabled={buttonLoading}
        >
            {buttonLoading ? 'Loading...' : text}
        </Button>
    );
};

DynamicButton.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    customClass: PropTypes.string,
    type: PropTypes.string,
    buttonType: PropTypes.number, // 0->Save 1->Update 2->Delete 3->Send
};

export default DynamicButton;
