import React, { useEffect, useState } from 'react';
import { Card, Input } from 'reactstrap';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { getInslug } from 'service/inslugService';
import customStyles from 'styles/customDatatableStyle';
import { useParams } from 'react-router-dom';
import { hasExcelPermission } from 'service/common/permissionService';

const ViewInslug = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [inslugData, setInslugData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [inslugSearch, setInslugSearch] = useState('');
  const [itemSearch, setItemSearch] = useState('');
  const [filteredInslugData, setFilteredInslugData] = useState([]);
  const [filteredItemData, setFilteredItemData] = useState([]);

    // Tarih olup olmadığını kontrol eden ve uygun formatta döndüren fonksiyon
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return dateString; // Geçerli bir tarih değilse olduğu gibi döndür
      }
  
      // Gün ay yıl saat dakika formatında döndür
      return date.toLocaleDateString("tr-TR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    };

  useEffect(() => {
    const fetchInslugData = async () => {
      try {
        const inslugInfo = await getInslug(id);
        const { inslug, inslugItems } = inslugInfo;

        const inslugArray = [
          { key: props.t("Company No"), value: inslug.client_no },
          { key: props.t("Transport Company No"), value: inslug.transport_company_no },
          { key: props.t("Document No"), value: inslug.document_no },
          { key: props.t("External Document No"), value: inslug.extern_document_no },
          { key: props.t("Agency Reference"), value: inslug.agency_reference },
          { key: props.t("Description"), value: inslug.description },
          { key: props.t("Booking Date"), value: inslug.booking_date ? formatDate(inslug.booking_date.toString()) : "-"},
          { key: props.t("ETA"), value: inslug.eta ? formatDate(inslug.eta.toString()) : "-" },
          { key: props.t("Closing Out"), value: inslug.closing_out ? formatDate(inslug.closing_out.toString()) : "-" },
          { key: props.t("Closing In"), value: inslug.closing_in ? formatDate(inslug.closing_in.toString()) : "-"},
          { key: props.t("Customs Status ID"), value: inslug.customs_status_id },
          { key: props.t("Supplier Company No"), value: inslug.supplier_company_no },
          { key: props.t("Carrier Type ID"), value: inslug.carrier_type_id },
          { key: props.t("Loading Status ID"), value: inslug.loading_status_id },
          { key: props.t("Process Status ID"), value: inslug.process_status_id },
        ];

        setInslugData(inslugArray);
        setItemData(inslugItems);
        setFilteredInslugData(inslugArray);
        setFilteredItemData(inslugItems);
      } catch (error) {
        console.error("Error fetching inslug data:", error);
      }
    };
    fetchInslugData();
  }, [dispatch, id]);

  useEffect(() => {
    setFilteredInslugData(
      inslugData.filter(row =>
        row.key.toLowerCase().includes(inslugSearch.toLowerCase()) ||
        row.value?.toString().toLowerCase().includes(inslugSearch.toLowerCase())
      )
    );
  }, [inslugSearch, inslugData]);

  useEffect(() => {
    setFilteredItemData(
      itemData.filter(row =>
        row.client_product_id?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.container_no?.toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.description?.toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.leverage_charge?.toString().toLowerCase().includes(itemSearch.toLowerCase()) ||
        row.count?.toString().toLowerCase().includes(itemSearch.toLowerCase())
      )
    );
  }, [itemSearch, itemData]);



  const inslugColumns = [
    {
      selector: row => row.key,
      sortable: false,
      wrap: true,
      style: {
        fontWeight: 'bold', // Kalın yazı
      }
    },
    {
      selector: row => row.value || "-",
      sortable: false,
      wrap: true,
    },
  ];

  const itemColumns = [
    {
      name: props.t("Client Product ID"),
      selector: row => row.client_product_id,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Container No"),
      selector: row => row.container_no,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Description"),
      selector: row => row.description || "-",
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Leverage Charge"),
      selector: row => row.leverage_charge,
      sortable: true,
      wrap: true,
    },
    {
      name: props.t("Count"),
      selector: row => row.count || "-",
      sortable: true,
      wrap: true,
    },
  ];

  const customPaginationOptions = {
    rowsPerPageText: props.t("Rows count"),
    rangeSeparatorText: '/',
  };

  return (
    <div>
      <Card className="p-4 rounded-lg mb-4">
        <h5 className="text-primary">{props.t("Inslug Information")}</h5>
        <DataTable
          columns={inslugColumns}
          data={filteredInslugData}
          customStyles={customStyles}
          highlightOnHover
          pagination
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          subHeader
          subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={inslugSearch}
                onChange={(e) => setInslugSearch(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
              />
            </div>
          }
        />
      </Card>

      <Card className="p-4 rounded-lg">
        <h5 className="text-primary">{props.t("Product Information")}</h5>
        <DataTable
          columns={itemColumns}
          data={filteredItemData}
          customStyles={customStyles}
          highlightOnHover
          pagination
          noDataComponent={props.t("table-empty-message")}
          paginationComponentOptions={customPaginationOptions}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          subHeader
          subHeaderComponent={
            <div className='d-flex justify-content-end w-50'>
              <Input
                type="text"
                placeholder={props.t("Search")}
                value={itemSearch}
                onChange={(e) => setItemSearch(e.target.value.toLocaleUpperCase("tr"))}
                className="form-control w-50"
              />
            </div>
          }
        />
      </Card>
    </div>
  );
};

ViewInslug.propTypes = {
  match: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(ViewInslug);
