import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import Breadcrumb from "./Breadcrumb/reducer";  

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Chat
import chat from "./chat/reducer";

// Calendar
import calendar from "./calendar/reducer";

// Tasks
import tasks from "./tasks/reducer";

import inslugReducer from "./depot/inslug/reducer";
import inslugItemReducer from "./depot/inslugItem/reducer";
import selectedProductsReducer from "./depot/selectedProducts/reducer";
import companyReducer from "./company/reducer";
import modalReducer from "./modal/reducer";
import externCompanyReducer from "./externCompany/reducer";
import updateInslugReducer from "./depot/updateInslug/reducer";
import productReducer from "./product/reducer";
import updateInslugItemReducer from "./depot/updateInslugItem/reducer";
import selectedUpdateProductsReducer from "./depot/selectedUpdateProducts/reducer";
import productGroupReducer from "./productGroup/reducer";
import clientProductReducer from "./clientProduct/reducer";
import countriesReducer from "./Definitions/country/reducer";
import citiesReducer from "./Definitions/city/reducer";
import timerReducer from "./timer/reducer";
import selectedClientProductsReducer from "./depot/selectedClientProducts/reducer";
import selectedUpdateClientProductsReducer from "./depot/selectedUpdateClientProducts/reducer";
import countryFormReducer from "./updates/countryForm/reducer";
import updateModalReducer from "./updateModal/reducer";
import selectedCountryReducer from "./selectedCountry/reducer";
import companyFormReducer from "./updates/companyForm/reducer";
import selectedCityReducer from "./selectedCity/reducer";
import filteredCompanyReducer from "./filteredCompanies/reducer";
import productFormReducer from "./updates/productForm/reducer";
import selectedProductGroupReducer from "./selectedProductGroup/reducer";
import clientProductFormReducer from "./updates/clientProductForm/reducer";
import selectedCompanyReducer from "./selectedCompany/reducer";
import selectedProductReducer from "./selectedProduct/reducer";
import isCreateReducer from "./isUpdate/reducer";
import addCityFormReducer from "./cityAddForm/reducer";
import cityFormReducer from "./updates/cityForm/reducer";
import addCompanyFormReducer from "./companyAddForm/reducer";
import permissionsReducer from "./permissions/reducer";
import selectedImageReducer from "./selectedImage/reducer";
import languagesReducer from "./languages/reducer";
import updateProductGroupFormReducer from "./updates/productGroupForm/reducer";
import supportFormReducer from "./support/reducer";
import userListFormReducer from "./userList/reducer";
import userUpdateFormReducer from "./updates/userForm/reducer";
import filteredRolesReducer from "./settings/filteredRoles/reducer";
import rolesReducer from "./settings/roles/reducer";
import selectedUpdateCountryReducer from "./selectedUpdateCountry/reducer";
import selectedUpdateCityReducer from "./selectedUpdateCity/reducer";
import buttonLoadingReducer from "./buttonLoading/reducer";
import addExternCompanyFormReducer from "./externCompanyAddForm/reducer";
import customsStatusReducer from "./Definitions/customsStatus/reducer";
import customsStatusFormReducer from "./updates/customsStatusForm/reducer";
import carrierTypeFormReducer from "./updates/carrierTypeForm/reducer";
import carrierTypesReducer from "./Definitions/carrierType/reducer";
import loadingStatusReducer from "./Definitions/loadingStatus/reducer";
import loadingStatusFormReducer from "./updates/loadingStatusForm/reducer";
import createDepotFormReducer from "./settings/createDepotForm/reducer";
import depotsReducer from "./settings/depots/reducer";
import updateDepotFormReducer from "./settings/updateDepotForm/reducer";
import createUnitFormReducer from "./settings/createUnitForm/reducer";
import unitsReducer from "./settings/units/reducer";
import updateUnitFormReducer from "./settings/updateUnitForm/reducer";
import createPartFormReducer from "./settings/createPartForm/reducer";
import updatePartFormReducer from "./settings/updatePartForm/reducer";
import partssReducer from "./settings/parts/reducer";
import processStatusReducer from "./Definitions/processStatus/reducer";
import processStatusFormReducer from "./updates/processStatusForm/reducer";
import definitionsReducer from "./Definitions/definitions/reducer";
import definitionItemsReducer from "./Definitions/definitionItems/reducer";
import selectedDefinitionReducer from "./selectedDefinition/reducer";
import selectedExternCityReducer from "./selectedExternCity/reducer";
import selectedDepotCityReducer from "./selectedDepotCity/reducer";

const rootReducer = combineReducers({
  // Public
  Layout,   
  // Breadcrumb items
  Breadcrumb,
  tasks,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,

  // Inslug ve InslugItem
  inslug: inslugReducer,
  updateInslug: updateInslugReducer,
  inslugItems: inslugItemReducer,
  updateInslugItems: updateInslugItemReducer,
  selectedProducts: selectedProductsReducer,
  selectedUpdateProducts: selectedUpdateProductsReducer,

  // Company
  companies: companyReducer,
  filteredCompanies: filteredCompanyReducer,
  selectedCompany: selectedCompanyReducer,
  addCompanyFormData: addCompanyFormReducer,

  // Extern Company
  addExternCompanyFormData: addExternCompanyFormReducer,
  externCompanies: externCompanyReducer,

  // Product
  products: productReducer,
  productGroups: productGroupReducer,
  clientProducts: clientProductReducer,
  selectedClientProducts: selectedClientProductsReducer,
  selectedUpdateClientProducts: selectedUpdateClientProductsReducer,
  selectedProductGroup: selectedProductGroupReducer,
  selectedProduct: selectedProductReducer,
  

  //Definitions
  definitions: definitionsReducer,
  definitionItems: definitionItemsReducer,
  selectedDefinition: selectedDefinitionReducer,
  countries: countriesReducer,
  selectedCountry: selectedCountryReducer,
  selectedUpdateCountry: selectedUpdateCountryReducer,
  selectedUpdateCity: selectedUpdateCityReducer,
  customsStatus: customsStatusReducer,
  carrierTypes: carrierTypesReducer,
  loadingStatus: loadingStatusReducer,
  processStatus: processStatusReducer,
  cities: citiesReducer,
  addCityFormData: addCityFormReducer,
  selectedCity: selectedCityReducer,
  selectedExternCity: selectedExternCityReducer,
  selectedDepotCity: selectedDepotCityReducer,

  // Modal
  modal: modalReducer,
  updateModal: updateModalReducer,

  // Timer
  timerOpen: timerReducer,

  // Button Loading
  buttonLoading: buttonLoadingReducer,

  // Permissions
  permissions: permissionsReducer,

  // Image
  selectedImage: selectedImageReducer,

  // Language
  languages: languagesReducer,

  // Support
  support: supportFormReducer,

  // User
  userList: userListFormReducer,

  // Roles
  roles: rolesReducer,
  filteredRoles: filteredRolesReducer,

  // Depots
  createDepotFormData: createDepotFormReducer,
  updateDepotFormData: updateDepotFormReducer,
  depots: depotsReducer,

  // Units
  createUnitFormData: createUnitFormReducer,
  updateUnitFormData: updateUnitFormReducer,
  units: unitsReducer,

  // Parts
  createPartFormData: createPartFormReducer,
  updatePartFormData: updatePartFormReducer,
  parts: partssReducer,

  // Updates
  // isUpdate
  isCreate: isCreateReducer,
  // Country Update
  countryFormData: countryFormReducer,
  // City Update
  cityFormData: cityFormReducer,
  //Company Update
  companyFormData: companyFormReducer,
  // Product Group
  productFormData: productFormReducer,
  // Client Product
  clientProductFormData: clientProductFormReducer,
  // Product Group
  updateProductGroupFormData: updateProductGroupFormReducer,
  // User
  updateUserFormData: userUpdateFormReducer,
  // Customs Status
  customsStatusFormData: customsStatusFormReducer,
  //Carrier Tyoe
  carrierTypeFormData: carrierTypeFormReducer,
  // Loading Status
  loadingStatusFormData: loadingStatusFormReducer,
  // Process Status
  processStatusFormData: processStatusFormReducer,
});

export default rootReducer;
